import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class Areas {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }
  
  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idArea}='${value}'`);
    result = dba.getAll(result);
    return result;
  }
  
  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);

  }

  static toItem(data) {
 
    let result = {
      [fields.idArea]:data.id,
      [fields.areaName]:data['field_193'],
      [fields.areaCode]:data['field_194'] ? data['field_194'] : 0,
      [fields.minBatch_powder]:data['field_195'] ? data['field_195'] : 0,
      [fields.maxBatch_powder]:data['field_196'] ? data['field_196'] : 0,
      [fields.minBatch_liquid]:data['field_197'] ? data['field_197'] : 0,
      [fields.maxBatch_liquid]:data['field_198'] ? data['field_198'] : 0,
    }

    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

}
