export const name = 'Category';
export const fields = {
  id: 'id',
  idCategory: 'idCategory',
  categoryCode: 'categoryCode',
  category: 'category',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idCategory, type: 'text' },
    { name: fields.categoryCode, type: 'text' },
    { name: fields.category, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
