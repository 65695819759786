import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class Labour {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}` );
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }
  
  static async findItem(value1,value2) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.mixSize}<=${value1} && ${fields.mixType}=\'${value2}\'`);
    result = dba.getAll(result);
    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data) {
 
    let result = {
      [fields.idLabour]:data.id,
      [fields.mixSize]:data['field_304'] ? data['field_304']: 0,
      [fields.hours]:data['field_303'] ? data['field_303'] : '0',
      [fields.mixType]:data['field_317_raw'][0] ? data['field_317_raw'][0].id : ''
    }

    return result;
  }

}
