/*
 *
 * NewRecommendation constants
 *
 */

export const DEFAULT_ACTION = "app/ewRecommendation/DEFAULT_ACTION";
export const GET_DATA_CLIENT = "app/NewRecommendation/GET_DATA_CLIENT";
export const GET_DATA = "app/NewRecommendation/GET_DATA";
export const GET_DATA_RECOMMENDAION = "app/NewRecommendation/GET_DATA_RECOMMENDAION";
export const GET_RECOMMENDATION_LIST = "app/NewRecommendation/GET_RECOMMENDATION_LIST";
export const MODAL = "app/NewRecommendation/MODAL";
export const DROPDOWN = "app/NewRecommendation/DROPDOWN";
export const RESET = "app/NewRecommendation/RESET";

export const PUSH_DATA = "app/NewRecommendation/PUSH_DATA";
export const PUSH_DATA_SUCCESS = "app/NewRecommendation/PUSH_DATA_SUCCESS";
export const GET_DATA_ELEMENT = "app/NewRecommendation/GET_DATA_ELEMENT";
export const GET_DATA_PRODUCT= "app/NewRecommendation/GET_DATA_PRODUCT";
export const DELETE_RECOMMENDAION = "app/NewRecommendation/DELETE_RECOMMENDAION";
export const GET_DISCOUNT = "app/NewRecommendation/GET_DISCOUNT";

export const GET_RECOMMENDATION = "app/NewRecommendation/GET_RECOMMENDATION";

