export const name = 'Areas';
export const fields = {
  id: 'id',
  idArea: 'value',
  areaName: 'label',
  areaCode: 'areaCode',
  minBatch_powder: 'minBatch_powder',
  maxBatch_powder: 'maxBatch_powder',
  minBatch_liquid: 'minBatch_liquid',
  maxBatch_liquid: 'maxBatch_liquid',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idArea, type: 'text' },
    { name: fields.areaName, type: 'text' },
    { name: fields.areaCode, type: 'number' },
    { name: fields.minBatch_powder, type: 'number' },
    { name: fields.maxBatch_powder, type: 'number' },
    { name: fields.minBatch_liquid, type: 'number' },
    { name: fields.maxBatch_liquid, type: 'number' },
  ],
  pk: [fields.id],
};
export default schema;
