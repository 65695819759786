export const name = 'Account';
export const fields = {
  id: 'id',
  idAccount: 'idAccount',
  idManage: 'idManage',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  profileKey: 'profileKey',
  nutritechMargin: 'nutritechMargin',
  resellerMargin: 'resellerMargin',
  markupMin: 'markupMin',
  markupMax: 'markupMax',
  acceptTerm: 'acceptTerm'
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'text PRIMARY KEY' },
    { name: fields.idAccount, type: 'text' },
    { name: fields.idManage, type: 'text' },
    { name: fields.email, type: 'text' },
    { name: fields.firstName, type: 'text' },
    { name: fields.lastName, type: 'text' },
    { name: fields.profileKey, type: 'text' },
    { name: fields.nutritechMargin, type: 'text' },
    { name: fields.resellerMargin, type: 'text' },
    { name: fields.markupMin, type: 'text' },
    { name: fields.markupMax, type: 'text' },
    { name: fields.acceptTerm, type: 'integer' },
  ],
  pk: [fields.id],
};
export default schema;
