import Q from 'q';

const DBA = {
 
  execute: function(query) {

    let q = Q.defer();

/* eslint-disable-next-line*/
    db.$sql.run(query).then(
        result => {

          if(result.length == 0) {
            console.log("WARNING result length is 0")
          }
        
          q.resolve(result);
        }).catch(function(err) {
          console.log('I found an error', query);
          console.log(err);
          q.reject(err);
      });

    return q.promise;
  },
  executeQryMapped: function(sql, mappings, upsert) {

    let q = Q.defer();
    let qry;
   
    try {
       /* eslint-disable-next-line*/
      qry = new db.$sql.Query(sql);

      mappings.forEach(m => {
        qry.map(m.key, m.value);
      })
    }
    catch(err) {
      console.log('Error loading query', sql);
      console.log(err);
      q.reject(err);
    }


    if(upsert) {
      qry.query_.data.upsert = true;
    }

/* eslint-disable-next-line*/
    db.$sql.run(qry).then(
        result => {

          if(result.length == 0) {
            console.log("WARNING result length is 0")
          }

          q.resolve(result);
        }).catch(function(err) {
          console.log(err);
          console.log('Error qry was', qry);
          q.reject(err);
      });

    return q.promise;
  },
  executeQry: function(sql, data, upsert) {

    let q = Q.defer();
   
    let qry;
    try {
       /* eslint-disable-next-line*/
      qry = new db.$sql.Query(sql);
      qry.map("@values", data);
    }
    catch(err) {
      console.log('Error loading query', sql);
      console.log(err);
      q.reject(err);
    }

    if(upsert) {
      qry.query_.data.upsert = true;
    }

/* eslint-disable-next-line*/
    db.$sql.run(qry).then(
        result => {
          q.resolve(result);
        }).catch(function(err) {
          console.log(err);
          console.log('Error qry was', qry);
          q.reject(err);
      });

    return q.promise;
  },
  // Proces a result set
  getAll: function(result) {

    return result; // data format changed after porting to IndexedDB, no longer needs modification.

    let output = [];
   // if(result.rows) {
    /*  for (let i = 0; i < result.rows.length; i++) {
        output.push(result.rows.item(i));
      }*/
   // }

   for (let i = 0; i < result.length; i++) {
    output.push(result.item(i));
   }

    return output;
  },
};

export default DBA;
