export const name = 'Element';
export const fields = {
  id: 'id',
  idElement: 'idElement',
  name: 'name',
  category: 'category',
  categoryId: 'categoryId',
  element: 'element',
  elementId: 'elementId',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'text PRIMARY KEY' },
    { name: fields.category, type: 'text' },
    { name: fields.categoryId, type: 'text' },
    { name: fields.element, type: 'text' },
    { name: fields.elementId, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
