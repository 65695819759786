import { merge } from "lodash";
import dba from "utils/dba";
import { mergeDataByField } from "utils/helpers";
import { fields, name } from "./schema";

export default class Product {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name} `);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async getElement(value) {
    let result = await dba.execute(
      `SELECT * FROM ${name} WHERE ${fields.category}=\'${value.data1}\' && ${
        fields.mixType
      }=\'${value.data2}\'`
    );
    result = dba.getAll(result);
    return result;
  }

  static async findItem(value) {
    let result = await dba.execute(
      `SELECT * FROM ${name} WHERE ${fields.idProduct}=\'${value}\'`
    );
    result = dba.getAll(result);
    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async filterByConditionLike(params) {
    const joinAnd = (obj) => {
      return Object.keys(obj).map(key => {
        return `${key} LIKE '%${obj[key]}%'`;
      }).join(" && ");

    };
    console.log("joinAnd", joinAnd(params));
    const sql = `
    SELECT *
    FROM ${name}
    WHERE ${joinAnd(params)}
    `;

    console.log("filterByConditionLike", sql);
    return dba.execute(sql).then((rs) => {
      return dba.getAll(rs);
    });
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data) {
 
    let result = {
      [fields.idProduct]:data.id,
      [fields.category]:data["field_22_raw"][0] ? data["field_22_raw"][0].identifier : "",
      [fields.element]:data["field_264_raw"][0] ? data["field_264_raw"][0].identifier : "",
      [fields.productName]:data['field_15'],
      [fields.autoAdd]:data['field_240'],
      [fields.elementalCalculation]:data["field_243"] ? parseFloat(data["field_243"]) : 0,
      [fields.dosePerCow]:data["field_245"] ? parseFloat(data["field_245"]) : 0,
      [fields.dosePerCowUnit]:data['field_331'],
      [fields.gramsPesBatch]:data["field_246"] ? parseFloat(data["field_246"]) : 0,
      [fields.costPerBatch]:data["field_248"] ? parseFloat(data["field_248"]) : 0,
      [fields.unit]:data["field_140"] ? data["field_140"] : "",
      [fields.ncr]:data["field_24_raw"][0] ? data["field_24_raw"][0].id : "",
      [fields.mixType]:mergeDataByField(data["field_25_raw"], 'id'),
      [fields.costPer]:data["field_19_raw"],
      [fields.ingredient]:data["field_20"] ? data["field_20"] : "",
      [fields.balancingAgent]:data["field_263"],
      [fields.defaultValue]:data["field_293"] != "" ? data["field_293"] : 0
     
    }

    // "${mergeDataByField(data["field_25_raw"], 'id')}",    todo, check above where this function called. to left is old format.

    return result;
  }
}
