import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
import {createUUID} from "../../utils/helpers";
import app_config from "configApp";

export default class RecommendationHeader {
  static async getAll(value) {
    console.log('idClient', value);
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.client}='${value}' && ${fields.updateData}!='delete' ORDER BY ${fields.id} desc`);
    result = dba.getAll(result);
    return result;
  }
  static async getAllData(value) {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }
  static async getItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idCode}='${value}'`);
    result = dba.getAll(result);
    return result;
  }
  static async databaseExit(value) {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name} WHERE ${fields.addedBy}='${value}'`);
  }

  static async deleteAll() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.idRecommendation}!='new'`);
  }

  static async deleteItem(value) {
    return dba.execute(`UPDATE ${name} SET ${fields.updateData} = 'delete' WHERE ${fields.idCode}='${value}'`);
  }

  static async deleteUnsyncData() {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.idRecommendation} = 'new'`);
  }

  static async loseDelete(value) {
    return dba.execute(`UPDATE ${name} SET ${fields.updateData} = '' WHERE ${fields.idRecommendation} = '${value}'`);
  }

  static async findItemOld() {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idRecommendation} != 'new'`);
    result = dba.getAll(result);
    return result;
  }

  static async deleteDataItem(value) {
    console.log(`DELETE FROM ${name} WHERE  ${fields.idCode}='${value}'`)
    return dba.execute(`DELETE FROM ${name} WHERE  ${fields.idCode}='${value}'`);
  }

  static async findItem(value1, value2) {
    if (value2 === '') {
      let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.updateData}='${value1}'`);
      result = dba.getAll(result);
      return result;
    } else {
      let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.updateData}='${value1}' || ${fields.updateData}='${value2}'`);
      result = dba.getAll(result);
      return result;
    }
  }

  static async update(id, field, value) {
   // if(field == fields.idCode) {
     // return dba.execute(`UPDATE ${name} SET ${field} = ${value} WHERE id = '${id}'`);
    //}
   // else
    //{
      return dba.execute(`UPDATE ${name} SET ${field} = '${value}' WHERE id = '${id}'`);
    //}
  }

  static async updateByField(fieldWhere, valueWhere, field, value) {

   // if(field == fields.idCode) {
      //return dba.execute(`UPDATE ${name} SET ${field} = ${value} WHERE ${fieldWhere} = '${valueWhere}'`);
    //}
   // else
   // {
      return dba.execute(`UPDATE ${name} SET ${field} = '${value}' WHERE ${fieldWhere} = '${valueWhere}'`);
   // }
  }

  static toKnack(data) {
    let result = {};
    result['field_108'] = [{ 'id': `${data.client}` }];
    result['field_152'] = `${data.reference}`;
    result['field_123'] = `${data.orderType}`;
    result['field_124'] = `${data.mixType}`;
    result['field_125'] = `${data.delivery}`;
    result['field_254'] = [{ 'id': `${data.packaging}` }];
    result['field_130'] = data.discount;
    result['field_109'] = data.numberOfCows;
    result['field_122'] = data.daysRequired;
    result['field_110'] = data.doseSize;
    result['field_250'] = data.totalRequired;
    result['field_137'] = `${data.dayilyHerdDose}`;
    result['field_138'] = data.batchSize;
    result['field_244'] = data.dosesPerBatch;
    result['field_251'] = data.costPerBatch;
    result['field_256'] = data.labourCost;
    result['field_257'] = data.packageCost;
    result['field_258'] = `${data.freight}`;
    result['field_259'] = `${data.totalCost}`;
    result['field_215'] = [{ 'id': `${data.status}` }];
    result['field_154'] = `${data.dateAdded}`;
    result['field_216'] = `${data.dateConfirmed}`;
    result['field_213'] = {
      "id": "",
      "type": "",
      "filename": "",
      "public_url": "",
      "thumb_url": "",
      "size": 0
    }
    result['field_217'] = `${data.notes}`;
    result['field_225'] = [{ 'id': `${data.addedBy}` }];
    result['field_270'] = `${data.resellerPhone}`;
    result['field_271'] = `${data.resellerEmail}`;
    result['field_358'] = `${data.areaManager}`;
    result['field_272'] = `${data.areaManagerName}`;
    result['field_273'] = `${data.areaManagerPhone}`;
    result['field_274'] = `${data.areaManagerEmail}`;
    result['field_307'] = `${data.rawMaterialCost}`;
    result['field_309'] = `${data.labelCost}`;
    result['field_310'] = `${data.palletCost}`;
    result['field_312'] = `${data.deliveryCost}`;
    result['field_308'] = `${data.totalMaterialCost}`;
    result['field_314'] = `${data.sellingCost}`;
    result['field_316'] = `${data.reseller}`;
    result['field_352'] = `${data.updatedDate}`;
    result['field_353'] = `${data.nutritechMargin}`;
    result['field_354'] = `${data.resellerMargin}`;
    result['field_336'] = data.carrierRate;
    result['field_397'] = `${'v' + app_config.version}`;

    return result;
  }

  static toInsert(data) {
    console.log('to insert data recom header', data);

   /* let result = `(
      "${data.id ? data.id : id}",
      "${data.idRecommendation ? data.idRecommendation : id}",
      "${data.idCode}",
      "${data.client}",
      "${data.reference}",
      "${data.orderType}",
      "${data.mixType}",
      "${data.delivery}",
      "${data.packaging}",
      "${data.discount}",
      "${data.numberOfCows}",
      "${data.daysRequired}",
      "${data.doseSize}",
      "${data.totalRequired}",
      "${data.dayilyHerdDose}",
      "${data.batchSize}",
      "${data.dosesPerBatch}",
      "${data.costPerBatch}",
      "${data.labourCost}",
      "${data.packageCost}",
      "${data.freight}",
      "${data.totalCost}",
      "${data.status}",
      "${data.dateAdded}",
      "${data.dateConfirmed}",
      "${data.pdf}",
      "${data.notes}",
      "${data.addedBy}",
      "${data.resellerPhone}",
      "${data.resellerEmail}",
      "${data.areaManager}",
      "${data.areaManagerName}",
      "${data.areaManagerPhone}",
      "${data.areaManagerEmail}",
      "${data.rawMaterialCost}",
      "${data.labelCost}",
      "${data.palletCost}",
      "${data.deliveryCost}",
      "${data.totalMaterialCost}",
      "${data.sellingCost}",
      "${data.reseller}",
      '${data.updateData}',
      '${data.updatedDate}',
      '${data.nutritechMargin}',
      '${data.resellerMargin}',
      '${data.carrierRate}'
    )`*/

    let id = createUUID();

    let result = {
      [fields.id]:data.id ? data.id : id,
      [fields.idRecommendation]:data.idRecommendation ? data.idRecommendation : id,
      [fields.idCode]:data.idCode.toString(),
      [fields.client]:data.client,
      [fields.reference]:data.reference,
      [fields.orderType]:data.orderType,
      [fields.mixType]:data.mixType,
      [fields.delivery]:data.delivery,
      [fields.packaging]:data.packaging.toString(),
      [fields.discount]:data.discount.toString(),
      [fields.numberOfCows]:parseInt(data.numberOfCows.toString()),
      [fields.daysRequired]:parseInt(data.daysRequired.toString()),
      [fields.doseSize]:data.doseSize.toString(),
      [fields.totalRequired]:data.totalRequired.toString(),
      [fields.dayilyHerdDose]:data.dayilyHerdDose.toString(),
      [fields.batchSize]:data.batchSize.toString(),
      [fields.dosesPerBatch]:data.dosesPerBatch.toString(),
      [fields.costPerBatch]:data.costPerBatch.toString(),
      [fields.labourCost]:data.labourCost.toString(),
      [fields.packageCost]:data.packageCost.toString(),
      [fields.freight]:data.freight.toString(),
      [fields.totalCost]:data.totalCost.toString(),
      [fields.status]:data.status,
      [fields.dateAdded]:data.dateAdded,
      [fields.dateConfirmed]:data.dateConfirmed,
      [fields.pdf]:data.pdf,
      [fields.notes]:data.notes,
      [fields.addedBy]:data.addedBy,
      [fields.resellerPhone]:data.resellerPhone,
      [fields.resellerEmail]:data.resellerEmail,
      [fields.areaManager]:data.areaManager,
      [fields.areaManagerName]:data.areaManagerName,
      [fields.areaManagerPhone]:data.areaManagerPhone,
      [fields.areaManagerEmail]:data.areaManagerEmail,
      [fields.rawMaterialCost]:data.rawMaterialCost.toString(),
      [fields.labelCost]:data.labelCost.toString(),
      [fields.palletCost]:data.palletCost.toString(),
      [fields.deliveryCost]:data.deliveryCost.toString(),
      [fields.totalMaterialCost]:data.totalMaterialCost.toString(),
      [fields.sellingCost]:data.sellingCost.toString(),
      [fields.reseller]:data.reseller,
      [fields.updateData]:data.updateData,
      [fields.updatedDate]:data.updatedDate,
      [fields.nutritechMargin]:data.nutritechMargin.toString(),
      [fields.resellerMargin]:data.resellerMargin.toString(),
      [fields.carrierRate]:parseFloat(data.carrierRate.toString())
    }
  
    return result;
  }

  static async create(data) {

    if(Array.isArray(data)) {
      const bulkSql = `INSERT INTO ${name} VALUES='@values'`;
      return dba.executeQry(bulkSql, data, true);
    }
    else
    {
      const sql = `INSERT INTO ${name} VALUES='@values'`;
      return dba.executeQry(sql, [data], true);
    }
  }

  static toItem(data, extension) {

    let id = createUUID();

    let result = {
      [fields.id]:data.id ? data.id : id,
      [fields.idRecommendation]:data.id ? data.id : id,
      [fields.idCode]:data['field_107'].toString(),
      [fields.client]:data['field_108_raw'][0] ? data['field_108_raw'][0].id : '',
      [fields.reference]:data['field_152'],
      [fields.orderType]:data['field_123'],
      [fields.mixType]:data['field_124'],
      [fields.delivery]:data['field_125'],
      [fields.packaging]:data['field_254_raw'][0] ? data['field_254_raw'][0].id : '',
      [fields.discount]:data['field_130'],
      [fields.numberOfCows]:data['field_109'],
      [fields.daysRequired]:data['field_122'],
      [fields.doseSize]:data['field_110'],
      [fields.totalRequired]:data['field_250'],
      [fields.dayilyHerdDose]:data['field_137'],
      [fields.batchSize]:data['field_138'],
      [fields.dosesPerBatch]:data['field_244'],
      [fields.costPerBatch]:data['field_251'],
      [fields.labourCost]:data['field_256'],
      [fields.packageCost]:data['field_257'],
      [fields.freight]:data['field_258'],
      [fields.totalCost]:data['field_259'],
      [fields.status]:data['field_215_raw'] && data['field_215_raw'][0] ? (data['field_215_raw'][0].identifier) : (''),
      [fields.dateAdded]:data['field_154'],
      [fields.dateConfirmed]:data['field_216'],
      [fields.pdf]:data['field_213_raw'] ? (data['field_213_raw'].url) : (''),
      [fields.notes]:data['field_217'],
      [fields.addedBy]:data['field_225_raw'] && data['field_225_raw'][0] ? (data['field_225_raw'][0].identifier) : (''),
      [fields.resellerPhone]:data['field_270'],
      [fields.resellerEmail]:data['field_271'],
      [fields.areaManager]:data['field_358_raw'] && data['field_358_raw'][0] ? (data['field_358_raw'][0].id) : (''),
      [fields.areaManagerName]:data['field_272'],
      [fields.areaManagerPhone]:data['field_273'],
      [fields.areaManagerEmail]:data['field_274'],
      [fields.rawMaterialCost]:data['field_307'],
      [fields.labelCost]:data['field_309'],
      [fields.palletCost]:data['field_310'],
      [fields.deliveryCost]:data['field_312'],
      [fields.totalMaterialCost]:data['field_308'],
      [fields.sellingCost]:data['field_314'],
      [fields.reseller]:data['field_316_raw'] ? data['field_316_raw'][0] ? data['field_316_raw'][0].id : '' : '',
      [fields.updateData]:"",
      [fields.updatedDate]:data['field_352'],
      [fields.nutritechMargin]:data['field_353'],
      [fields.resellerMargin]:data['field_354'],
      [fields.carrierRate]:data['field_336_raw'] ? data['field_336_raw'] : 0
    }
  
    return result;
  }
}
