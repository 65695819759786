export const name = 'MaterialCosts';
export const fields = {
  id: 'id',
  idMaterialCosts: 'idMaterialCosts',
  mixType: 'mixType',
  freightPerUnit: 'freightPerUnit',
  labelCost: 'labelCost',
  palletCost: 'palletCost',
  ruralDeliveryCost: 'ruralDeliveryCost',
  costPerHour: 'costPerHour',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idMaterialCosts, type: 'text' },
    { name: fields.mixType, type: 'text' },
    { name: fields.freightPerUnit, type: 'text' }, //changed from number as value coming from knack is rounded to 2dp and is string.
    { name: fields.labelCost, type: 'text' },  //changed from number as value coming from knack is rounded to 2dp and is string.
    { name: fields.palletCost, type: 'text' },  //changed from number as value coming from knack is rounded to 2dp and is string.
    { name: fields.ruralDeliveryCost, type: 'text' },  //changed from number as value coming from knack is rounded to 2dp and is string.
    { name: fields.costPerHour, type: 'text' }  //changed from number as value coming from knack is rounded to 2dp and is string.
  ],
  pk: [fields.id],
};
export default schema;
