/**
 *
 * HomePage
 *
 */
// library
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

// sage, reducer,..
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";
import { globalData } from "../App/selectors";
import { updateGlobalData } from "../App/actions";
import reducer from "./reducer";
import saga from "./saga";
import { syncData } from "../App/actions";
import "./style.scss";
import logo from 'images/logo.png';
import LogRocket from 'logrocket';
import {
  fetchData,
  hideSpinner,
  logOut,
  getData,
  checkUnsyncData
} from "../App/actions";
import { checkTermsConditions } from "../TermsConditions/actions";
import { resetData } from "../NewRecommendation/actions";
import { resetDataClient } from "../ClientListPage/actions";
import { checkConnection, formatTimeHome, getLocalStorage } from "utils/helpers";
//components
import NavigationBar from "components/NavigationBar";
import WarningModal from "components/WarningModal";
import { login } from "../SignInPage/actions";
import Account from "models/Account";
import { config } from "react-transition-group";
import app_config from "configApp";
export class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }
  componentWillMount(){
    let email = getLocalStorage("currentUser", false);

    LogRocket.identify(email); 

  }
  componentDidMount() {
    this.props.resetData();
    this.props.resetDataClient();
  }
  onNewRecommendationClick = () => {
    this.props.history.push("/new-recommendation");
  };

  onManageRecommendationClick = () => {
    this.props.history.push("/manage-recommendations");
  };

  onResellerRecommendationClick = () => {
    this.props.history.push("/reseller-recommendations");
  };

  onClientsClick = () => {
    this.props.history.push("/clients");
  };

  onLogoutClick = async () => {
    let remainingData = await this.props.checkUnsyncData();
    window.localStorage.setItem("authToken", "");
    window.localStorage.setItem("accept", "");
    window.localStorage.setItem("currentUser", "");

    let keysToRemove = ['firstName','syncData','roleAccount','listClient','flagSyncData','nutritechMargin','resellerMargin','contactPhone'];
    keysToRemove.forEach(k => {
      window.localStorage.removeItem(k);
    })

    this.props.checkTermsConditions(false);
    this.props.getData("firstName", "");
    this.props.getData("syncData", "");
    this.props.logOut();
    this.props.history.push("/sign-in");
  };

  onSync = async () => {
    // Show spinner
    this.props.hideSpinner(true);
    let accept = getLocalStorage("accept", false);

    let refreshHomePath = '/home?refresh=' + new Date().getMilliseconds();

    this.props.syncData(accept === "true" ? refreshHomePath : "/terms-conditions", true, this.props.history);
  };

  reload = async () => {

    let connectionOk = await checkConnection();

    if(connectionOk) {

        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then(registrations => {
            if (registrations.length > 0) {
              // Unregister any existing service workers
              for (let registration of registrations) {
                registration.unregister();
              }
              // Reload the page with a hard reload
              location.reload(true);
            } else {
              // No service workers to unregister, just reload the page
              location.reload(true);
            }
          });
        } else {
          // If service workers are not supported, just reload the page
          location.reload(true);
        }
    }
    else
    {
      alert('An internet connection is required to download the latest version of the this app.')
    }
  };

  render() {
    let needsSync = window.localStorage.getItem('needsSync');
    const { firstName, syncData, roleAccount } = this.props.globalData;
    return (
      <div className={"home-page"}>
        <NavigationBar
          iconLeft={"icon-logout"}
          textIcon={"Logout"}
          onLeftClick={() => {
            this.setState({
              modal: true
            });
          }}
        />
        <div className={"welcome-container"}>
          <div className={"logo"}>
            <img src={logo} alt="main-logo" />
          </div>
          <div className={"title"}>WELCOME BACK {firstName}!</div>
        </div>

        <div className={"main-option"}>
          <div
            className={`main-option-container ${
              this.props.globalData.roleAccount
            }`}
          >
            <button
              className={"btn-group"}
              onClick={this.onNewRecommendationClick}
            >
              New Recommendation
            </button>
            <button
              className={"btn-group"}
              onClick={this.onManageRecommendationClick}
            >
              Manage Recommendations
            </button>
            {roleAccount != "reseller" ? (
              <button
                className={"btn-group"}
                onClick={this.onResellerRecommendationClick}
              >
                View Reseller Recommendations
              </button>
            ) : (
              <div />
            )}
            <button className={"btn-group"} onClick={this.onClientsClick}>
              Your clients
            </button>
          </div>
        </div>
        <div className={"footer"}>
            <div className={"sync-data"}>
              
              <button className={"btn-sync"} onClick={this.onSync}>
                <i className="icon-white-sync" />
                Sync Data
              </button>
              {(needsSync == 'true') &&
                <span className='needs-sync'>
                  Unsynced Data!
                </span>
                }
            </div>
            <div className={"last-time"}>
              <span>Last sync: </span>
              {formatTimeHome(syncData)}
            </div>
            <div className={"version"}>
              <span>v{app_config.version}</span>
              <br />
              <span className={"reload-link"} onClick={this.reload}>
                <span>reload app</span>
              </span>
            </div> 
        </div>
        <WarningModal
          visible={this.state.modal}
          title="LOG OUT"
          content={
            "Are you sure you want to logout of the app ? Unsynced data will lost after logout."
          }
          cancelText={"NO"}
          onCancelClick={() => {
            this.setState({ modal: false });
          }}
          okText={"YES"}
          onOkClick={() => {
            this.setState({ modal: false });
            this.onLogoutClick();
          }}
        />
      </div>
    );
  }
}

HomePage.propTypes = {
  //   fetchData : PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  globalData: globalData()
});

function mapDispatchToProps(dispatch) {
  return {
    // checkTermsConditions: (value) => {
    //     dispatch(checkTermsConditions(value))
    // },
    fetchData: () => {
      dispatch(fetchData());
    },

    hideSpinner: value => {
      dispatch(hideSpinner(value));
    },

    logOut: () => {
      dispatch(logOut());
    },
    resetData: () => {
      dispatch(resetData());
    },
    resetDataClient: () => {
      dispatch(resetDataClient());
    },
    syncData: (path, forceRefresh, history) => {
      dispatch(syncData(path, forceRefresh, history));
    },
    getData: (key, value) => {
      dispatch(getData(key, value));
    },
    checkTermsConditions: value => {
      dispatch(checkTermsConditions(value));
    },
    // syncData: async () => {
    //   return new Promise((resolve, reject) => {
    //     return dispatch(syncData({ resolve, reject }));
    //   });
    // },
    updateGlobalData: (key, value) => {
      dispatch(updateGlobalData(key, value));
    },

    async checkUnsyncData() {
      return new Promise((resolve, reject) => {
        return dispatch(checkUnsyncData(resolve, reject));
      });
    }
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const withReducer = injectReducer({ key: "allData", reducer });
const withSaga = injectSaga({ key: "allData", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect
)(HomePage);
