export const name = 'RecommendationHeader';
export const fields = {
  id: 'id',
  idRecommendation: 'idRecommendation',
  idCode: 'idCode',
  client: 'client',
  reference: 'reference',
  orderType: 'orderType',
  mixType: 'mixType',
  delivery: 'delivery',
  packaging: 'packaging',
  discount: 'discount',
  numberOfCows: 'numberOfCows',
  daysRequired: 'daysRequired',
  doseSize: 'doseSize',
  totalRequired: 'totalRequired',
  dayilyHerdDose: 'dayilyHerdDose',
  batchSize: 'batchSize',
  dosesPerBatch: 'dosesPerBatch',
  costPerBatch: 'costPerBatch',
  labourCost: 'labourCost',
  packageCost: 'packageCost',
  freight: 'freight',
  totalCost: 'totalCost',
  status: 'status',
  dateAdded: 'dateAdded',
  dateConfirmed: 'dateConfirmed',
  pdf: 'pdf',
  notes: 'notes',
  addedBy: 'addedBy',
  resellerPhone: 'resellerPhone',
  resellerEmail: 'resellerEmail',
  areaManager: 'areaManager',
  areaManagerName: 'areaManagerName',
  areaManagerPhone: 'areaManagerPhone',
  areaManagerEmail: 'areaManagerEmail',
  rawMaterialCost: 'rawMaterialCost',
  labelCost: 'labelCost',
  palletCost: 'palletCost',
  deliveryCost: 'deliveryCost',
  totalMaterialCost: 'totalMaterialCost',
  sellingCost: 'sellingCost',
  reseller : 'reseller',
  updateData: 'updateData',
  updatedDate: 'updatedDate',
  nutritechMargin: 'nutritechMargin',
  resellerMargin: 'resellerMargin',
  carrierRate: 'carrierRate'
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'text PRIMARY KEY' },
    { name: fields.idRecommendation, type: 'text' },
    { name: fields.idCode, type: 'text' }, //number is coming from knack but store as string as new unsycned lines have guid.
    { name: fields.client, type: 'text' },
    { name: fields.reference, type: 'text' },
    { name: fields.orderType, type: 'text' },
    { name: fields.mixType, type: 'text' },
    { name: fields.delivery, type: 'text' },
    { name: fields.packaging, type: 'text' },
    { name: fields.discount, type: 'text' },
    { name: fields.numberOfCows, type: 'number' },
    { name: fields.daysRequired, type: 'number' },
    { name: fields.doseSize, type: 'text' },
    { name: fields.totalRequired, type: 'text' },
    { name: fields.dayilyHerdDose, type: 'text' },
    { name: fields.batchSize, type: 'text' },
    { name: fields.dosesPerBatch, type: 'text' },
    { name: fields.costPerBatch, type: 'text' },
    { name: fields.labourCost, type: 'text' },
    { name: fields.packageCost, type: 'text' },
    { name: fields.freight, type: 'text' },
    { name: fields.totalCost, type: 'text' },
    { name: fields.status, type: 'text' },
    { name: fields.dateAdded, type: 'text' },
    { name: fields.dateConfirmed, type: 'text' },
    { name: fields.pdf, type: 'text' },
    { name: fields.notes, type: 'text' },
    { name: fields.addedBy, type: 'text' },
    { name: fields.resellerPhone, type: 'text' },
    { name: fields.resellerEmail, type: 'text' },
    { name: fields.areaManager, type: 'text' },
    { name: fields.areaManagerName, type: 'text' },
    { name: fields.areaManagerPhone, type: 'text' },
    { name: fields.areaManagerEmail, type: 'text' },
    { name: fields.rawMaterialCost, type: 'text' },
    { name: fields.labelCost, type: 'text' },
    { name: fields.palletCost, type: 'text' },
    { name: fields.deliveryCost, type: 'text' },
    { name: fields.totalMaterialCost, type: 'text' },
    { name: fields.sellingCost, type: 'text' },
    { name: fields.reseller, type: 'text' },
    { name: fields.updateData, type: 'text' },
    { name: fields.updatedDate, type: 'text' },
    { name: fields.nutritechMargin, type: 'text' },
    { name: fields.resellerMargin, type: 'text' },
    { name: fields.carrierRate, type: 'number' }
  ],
  pk: [fields.id],
};
export default schema;
