/*
 *
 * ClientListPage constants
 *
 */

export const DEFAULT_ACTION = "app/ClientListPage/DEFAULT_ACTION";
export const SEARCH_CLIENTS = "app/ClientListPage/SEARCH_CLIENTS";

export const PUSH_DATA = "app/ClientListPage/PUSH_DATA";
export const PUSH_DATA_SUCCESS = "app/ClientListPage/PUSH_DATA_SUCCESS";
export const RESET = "app/ClientListPage/RESET";

