import { createGlobalStyle  } from 'styled-components';

/* eslint no-unused-expressions: 0 */
 const GlobalStyle = createGlobalStyle`　
  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Open Sans', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Open Sans', sans-serif;
  }

  #app {
    background-color: #ffffff;
    height: 100%;
    margin: auto;
    font-size: 14px;
  }
  h1, 
  h2, 
  h3, 
  h4, 
  h5, 
  h6, 
  p, 
  button, 
  input, 
  textarea, 
  html {
    font-family: 'Open Sans', sans-serif    ;
  }


  p,
  label {
    /* font-family: Georgia, Times, 'Times New Roman', serif; */
    line-height: 1.5em;
  }

  button {
    background-color: rgba(0,0,0,0);
    border: none
  }
`;

export default GlobalStyle;