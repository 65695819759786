export const name = 'ClientsList';
export const fields = {
  id: 'id',
  idClient: 'idClient',
  areaManager: 'areaManager',
  reseller: 'reseller',
  area: 'area',
  firstName: 'firstName',
  lastName: 'lastName',
  name: 'name',
  farmName: 'farmName',
  customerCode: 'customerCode',
  address: 'address',
  suburb: 'suburb',
  townCity: 'townCity',
  region: 'region',
  postCode: 'postCode',
  rd: 'rd',
  email: 'email',
  phone: 'phone',
  manageBy: 'manageBy',
  updateData : 'updateData',
  isReseller: 'isReseller'
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'text PRIMARY KEY' },
    { name: fields.idClient, type: 'text' },
    { name: fields.area, type: 'text' },
    { name: fields.areaManager, type: 'text' },
    { name: fields.reseller, type: 'text' },
    { name: fields.firstName, type: 'text' },
    { name: fields.lastName, type: 'text' },
    { name: fields.name, type: 'text' },
    { name: fields.farmName, type: 'text' },
    { name: fields.customerCode, type: 'text' }, //maps to dairy supply number in knack?
    { name: fields.address, type: 'text' },
    { name: fields.suburb, type: 'text' },
    { name: fields.townCity, type: 'text' },
    { name: fields.region, type: 'text' },
    { name: fields.postCode, type: 'number' },
    { name: fields.rd, type: 'text' },
    { name: fields.email, type: 'text' },
    { name: fields.phone, type: 'text' },
    { name: fields.manageBy, type: 'text' },
    { name: fields.updateData, type: 'text' },
    { name: fields.isReseller, type: 'text' }
  ],
  pk: [fields.id],
};
export default schema;
