import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
import {createUUID} from "../../utils/helpers";
import { SelectFieldColumn } from 'react-md';
export default class RecommendationLine {
  static async getAll(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.recommedationHeader}='${value}' && ${fields.updateData} != 'delete'`);
    result = dba.getAll(result);
    return result;
  }

  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.recommedationHeader}='${value}' && ${fields.updateData} != 'delete'`);
    result = dba.getAll(result);
    return result;
  }

  static async clearOldLines(value) {    
    return dba.execute(`UPDATE ${name} SET ${fields.updateData} = 'delete' WHERE ${fields.recommedationHeader}='${value}'`);
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async deleteItem(value) {
    return dba.execute(`UPDATE ${name} SET ${fields.updateData} = 'delete' WHERE ${fields.recommedationHeader}='${value}'`);
  }

  static async deleteAll() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async delete(id) {
    return dba.execute(`DELETE FROM ${name} WHERE id=${id}`);
  }

  static async tempDelete(id) {
    return dba.execute(`UPDATE ${name} SET ${fields.updateData} = 'delete' WHERE id='${id}'`);
  }

  static async deleteUnsyncData() {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.idRecommendation} = 'new'`);
  }

  static async loseDelete(value) {
    return dba.execute(`UPDATE ${name} SET ${fields.updateData} = '' WHERE ${fields.recommedationHeader} = '${value}'`);
  }

  static async deleteDataItem(value) {
    return dba.execute(`DELETE FROM ${name} WHERE  ${fields.recommedationHeader}='${value}'`);
  }

  static async getUnSyncedData() {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.updateData}='new' || ${fields.updateData}='update' || ${fields.updateData}='delete'`);
    result = dba.getAll(result);
    return result;
  }

  static async update(id, field, value) {
    
    return dba.execute(`UPDATE ${name} SET ${field} = '${value}' WHERE id = '${id}'`);
    
  }

  static async updateByField(fieldWhere, valueWhere, field, value) {

      return dba.execute(`UPDATE ${name} SET ${field} = '${value}' WHERE ${fieldWhere} = '${valueWhere}'`);
    
  }

  static toInsert(data) {
    let id = createUUID();
   /* let result = `(
      "${data.id ? data.id : id}",
      "${data.id ? data.idRecommendationLine : id}",
      "${data.idRecommendation}",
      "${data.recommedationHeader}",
      "${data.product}",
      "${data.inputDose}",
      "${data.elementalDose}",
      "${data.dosePerCow}",
      "${data.gramsPerBatch}",
      "${data.costPerBatch}",
      "${data.sortKey}",
      "${data.id ? 'update' : 'new'}",
      "${data.areaManager}"
    )`*/

    console.log('toInsert called in RecommendationLine');

    let result = {
      [fields.id]:data.id ? data.id : id,
      [fields.idRecommendationLine]:data.id ? data.idRecommendationLine : id,
      [fields.idRecommendation]:data.idRecommendation,
      [fields.recommedationHeader]:data.recommedationHeader, 
      [fields.product]:data.product,
      [fields.inputDose]:data.inputDose.toString(),
      [fields.elementalDose]:data.elementalDose.toString(),
      [fields.dosePerCow]:data.dosePerCow.toString(),
      [fields.gramsPerBatch]:data.gramsPerBatch.toString(),
      [fields.costPerBatch]:data.costPerBatch.toString(),
      [fields.sortKey]:data.sortKey,
      [fields.updateData]:data.id ? 'update' : 'new',
      [fields.areaManager]:data.areaManager,
    }

    return result;
  }
  static toKnack(data) {
    let result = {};
    result['field_111'] = [{ 'id': `${data.idRecommendation}` }];
    result['field_132'] = [{ 'id': `${data.product}` }];
    result['field_131'] = `${data.inputDose}`;
    result['field_242'] = `${data.elementalDose}`;
    result['field_241'] = `${data.dosePerCow}`;
    result['field_247'] = `${data.gramsPerBatch}`;
    result['field_249'] = `${data.costPerBatch}`;
    result['field_186'] = `${data.sortKey}`;
    result['field_359'] = [{ 'id': `${data.areaManager}` }];
    return result;
  }
  static async deleteData() {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.idRecommendation}!="new"`);
  }

  static async create(data) {

    if(Array.isArray(data)) {
      const bulkSql = `INSERT INTO ${name} VALUES='@values'`;
      return dba.executeQry(bulkSql, data, true);
    }
    else
    {
      const sql = `INSERT INTO ${name} VALUES='@values'`;
      return dba.executeQry(sql, [data], true);
    }
  }

  static toItem(data) {
    let id = createUUID();

    let result = {
      [fields.id]:data.id ? data.id : id,
      [fields.idRecommendationLine]:data.id ? data.id : id,
      [fields.idRecommendation]:data['field_111_raw'][0] ? data['field_111_raw'][0].id : '',
      [fields.recommedationHeader]:data['field_111_raw'][0] ? data['field_111_raw'][0].identifier.toString() : '0', 
      [fields.product]:data['field_132_raw'][0] ? data['field_132_raw'][0].id : '',
      [fields.inputDose]:data['field_131'],
      [fields.elementalDose]:data['field_242'],
      [fields.dosePerCow]:data['field_241'],
      [fields.gramsPerBatch]:data['field_247'],
      [fields.costPerBatch]:data['field_249'],
      [fields.sortKey]:data['field_186'],
      [fields.updateData]:"",
      [fields.areaManager]:data['field_359_raw'] && data['field_359_raw'][0] ? data['field_359_raw'][0].id : '',
    }

    return result;
  }
}
