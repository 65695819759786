import axios from "axios";
import config from "configApp";
import _ from "lodash";
import Account from "models/Account";
import Area from "models/Areas";
import Category from "models/Category";
import ClientsList from "models/ClientsList";
import ConfigFile from "models/ConfigFile";
import DataGlobalUpdate from "models/DataGlobalUpdate";
import Element from "models/Element";
import ImcompatibleProducts from "models/IncompatibleProducts";
import Labour from "models/Labour";
import MaterialCosts from "models/MaterialCosts";
import MixType from "models/MixType";
import Packaging from "models/Packaging";
import Product from "models/Product";
import RecommendationHeader from "models/RecommendationHeader";
import RecommendationLine from "models/RecommendationLine";
import Region from "models/Region";
import ResellersList from "models/ResellersList";
import Status from "models/Status";
import Mail from "models/Mail";
import moment from "moment";
import { all, call, delay, put, takeLatest } from "redux-saga/effects";
import DBA from "utils/dba";
import { checkConnection, getLocalStorage, removeLocalStorage, setLocalStorage } from "utils/helpers";
//import history from "utils/history";
import {
  configFile,
  configFileError,
  configFileSuccess,
  getAreaError,
  getAreas,
  getAreaSuccess,
  getCategory,
  getCategoryError,
  getCategorySuccess,
  getClients,
  getClientsError,
  getClientsSuccess,
  getData,
  getElementError,
  getElementSuccess,
  getImcompatibleProduct,
  getImcompatibleProductError,
  getImcompatibleProductSuccess,
  getLabour,
  getLabourError,
  getLabourSuccess,
  getMaterialCosts,
  getMaterialCostsError,
  getMaterialCostsSuccess,
  getMixType,
  getMixTypeError,
  getMixTypeSuccess,
  getNCRsError,
  getNCRsSuccess,
  getPackaging,
  getPackagingError,
  getPackagingSuccess,
  getProduct,
  getProductError,
  getProductSuccess,
  getRegion,
  getRegionError,
  getRegionSuccess,
  getResellers,
  getResellersError,
  getResellersSuccess,
  getStatus,
  getStatusError,
  getStatusSuccess,
  hideSpinner,
  recommendationHeader,
  recommendationHeaderError,
  recommendationHeaderSuccess,
  recommendationLine,
  recommendationLineError,
  recommendationLineSuccess,
  saveTermConditions,
  updateData,
  updateDataError,
  updateDataSuccess
} from "./actions";

import {
  CHECK_UNSYNC_DATA,
  CONFIG_FILE,
  FETCH_ALL,
  GET_AREAS,
  GET_CATEGORY,
  GET_CLIENTS,
  GET_ELEMENT,
  GET_IMCOMPATIBLE_PRODUCT,
  GET_MIX_TYPE,
  GET_NCR,
  GET_PACKAGING,
  GET_PRODUCT,
  GET_REGIONS,
  GET_RESELLERS,
  GET_STATUS,
  GET_TERM_CONDITIONS,
  LOG_OUT,
  RECOMMENDAION_HEADER,
  RECOMMENDAION_LINE,
  RESUME_APP,
  SEARCH_PRODUCT,
  SYNC_DATA,
  UPDATE_DATA,
  GET_DISCOUNT
} from "./constants";
import NCRs from "../../models/NCRs";
import { createUUID } from "../../utils/helpers";
import {
  getSumElementDose,
  getLineDosePerCow,
  getSumDosePerCow,
  getHeaderDoseSize,
  getHeaderTotalRequired,
  getHeaderBatchSize,
  getHeaderDosesPerBatch,
  getLineGramsPerBatch,
  getLineElementDose,
  getLineCostPerBatch, getSumCostPerBatch
} from "../../utils/calculation";

const urlArea = config.knack.requestUrl.areas;
const urlRegion = config.knack.requestUrl.regions;
const urlCategory = config.knack.requestUrl.categories;
const urlElement = config.knack.requestUrl.element;
const urlImcompatible = config.knack.requestUrl.incompatibleProducts;
const urlMixType = config.knack.requestUrl.mixTypes;
const urlNCRs = config.knack.requestUrl.ncrs;
const urlPackaging = config.knack.requestUrl.packagings;
const urlProduct = config.knack.requestUrl.products;
const urlStatus = config.knack.requestUrl.statuses;
const urlMaterialCosts = config.knack.requestUrl.materialCosts;
const urlLabours = config.knack.requestUrl.labours;
const urlClients = config.knack.requestUrl.clients;
const urlResellers = config.knack.requestUrl.resellers;
const urlSyncData = config.knack.requestUrl.syncData;
const urlRecommendationHeader = config.knack.requestUrl.recommendationHeaders;
const urlRecommendationLine = config.knack.requestUrl.recommendationLines;
const urlConfigFile = config.knack.requestUrl.configFile;
const urlFilePdf = config.knack.requestUrl.sendMail;
const urlProfile = config.knack.requestUrl.profile;
const urlResellerUser = config.knack.requestUrl.resellerUser;
const urlResellerClient = config.knack.requestUrl.clientsReseller;
const urlResellerRecommendationHeader = config.knack.requestUrl.recommendationHeadersReseller;
const urlResellerRecommendationLine = config.knack.requestUrl.recommendationLinesReseller;

/*function* fetchRegions() {
  try {
    const res = yield axios.get(urlRegion);
    yield put(getRegionSuccess(res));
  } catch (err) {
    yield put(getRegionError(err));
  }
}
function* fetchAreas() {
  try {
    const res = yield axios.get(urlArea);
    yield put(getAreaSuccess(res));
  } catch (err) {
    yield put(getAreaError(err));
  }
}

function* fetchCategories() {
  try {
    const res = yield axios.get(urlCategory);
    yield put(getCategorySuccess(res));
  } catch (err) {
    yield put(getCategoryError(err));
  }
}

function* fetchElement() {
  try {
    const res = yield axios.get(urlElement);
    yield put(getElementSuccess(res));
  } catch (err) {
    yield put(getElementError(err));
  }
}

function* fetchImcompatibleProducts() {
  try {
    const res = yield axios.get(urlImcompatible);
    yield put(getImcompatibleProductSuccess(res));
  } catch (err) {
    yield put(getImcompatibleProductError(err));
  }
}

function* fetchMixType() {
  try {
    const res = yield axios.get(urlMixType);
    yield put(getMixTypeSuccess(res));
  } catch (err) {
    yield put(getMixTypeError(err));
  }
}
function* fetchNCRs() {
  try {
    const res = yield axios.get(urlNCRs);
    yield put(getNCRsSuccess(res));
  } catch (err) {
    yield put(getNCRsError(err));
  }
}
function* fetchPackaging() {
  try {
    const res = yield axios.get(urlPackaging);
    yield put(getPackagingSuccess(res));
  } catch (err) {
    yield put(getPackagingError(err));
  }
}*/
/*function* fetchProduct() {
  try {
    const res = yield axios.get(urlProduct);
    yield put(getProductSuccess(res));
  } catch (err) {
    yield put(getProductError(err));
  }
}*/
/*function* fetchStatus() {
  try {
    const res = yield axios.get(urlStatus);
    yield put(getStatusSuccess(res));
  } catch (err) {
    yield put(getStatusError(err));
  }
}*/
/*function* fetchLabour() {
  try {
    const res = yield axios.get(urlLabours);
    yield put(getLabourSuccess(res));
  } catch (err) {
    yield put(getLabourError(err));
  }
}
function* fetchMaterialCosts() {
  try {
    const res = yield axios.get(urlMaterialCosts);
    yield put(getMaterialCostsSuccess(res));
  } catch (err) {
    yield put(getMaterialCostsError(err));
  }
}*/
function* fetchClients(url, data) {
  try {

    let startChar = '&';

    if(url.indexOf('?') < 0) {
        startChar = '?';
    }

    url = url+startChar;

    const res = yield axios.get(`${url}page=${data}`);
    yield put(getClientsSuccess(res));
  } catch (err) {
    yield put(getClientsError(err));
  }
}

/*function* fetchResellers() {
  try {
    const res = yield axios.get(urlResellers);
    yield put(getResellersSuccess(res));
  } catch (err) {
    yield put(getResellersError(err));
  }
}

function* fetchConfigFile() {
  try {
    const res = yield axios.get(urlConfigFile);
    yield put(configFileSuccess(res));
  } catch (err) {
    yield put(configFileError(err));
  }
}*/

function* fetchRecommendationHeader(url, data) {
  try {
    const res = yield axios.get(`${url}&page=${data}`);
    yield put(recommendationHeaderSuccess(res));
  } catch (err) {
    yield put(recommendationHeaderError(err));
  }
}

function* fetchRecommendationLine(url, data) {
  try {
    const res = yield axios.get(`${url}&page=${data}`);
    yield put(recommendationLineSuccess(res));
  } catch (err) {
    yield put(recommendationLineError(err));
  }
}

/*function* fetchSyncData() {
  try {
    const res = yield axios.get(urlSyncData);
    yield put(updateDataSuccess(res));
    //  sync data
  } catch (err) {
    yield put(updateDataError(err));
  }
}*/

function* logOut() {
  try {
    yield call(Account.deleteData);
    yield call(ClientsList.deleteAll);
    yield call(ResellersList.deleteAll);
    yield call(RecommendationHeader.deleteAll);
    yield call(RecommendationLine.deleteAll);
  } catch (err) {
    console.log(err);
  }
}

/*function* fetchAllData() {
  try {
    let acc = yield call(Account.getAll);
    let startTime = null;
    let stopTime = null;
    yield (startTime = Date.now());

    var today = moment(new Date()).format("MM/DD/YYYY, hh:mm:ss A");
    yield put(getData("syncData", today));

    let dataUpdate = yield DataGlobalUpdate.databaseExit(acc[0].email);
    if (DBA.getAll(dataUpdate)[0].numberOfRecord === 0) {
      yield put(updateData());
    }

    //  get name account
    yield put(getData("firstName", acc[0].firstName));

    let clients = yield ClientsList.databaseExit(acc[0].email);
    if (DBA.getAll(clients)[0].numberOfRecord === 0) {
      yield put(getClients());
    }

    let reseller = yield ResellersList.databaseExit(acc[0].email);
    if (DBA.getAll(reseller)[0].numberOfRecord === 0) {
      yield put(getResellers());
    }

    let products = yield Product.databaseExit();
    if (DBA.getAll(products)[0].numberOfRecord === 0) {
      yield put(getProduct());
    }

    let recommenHeader = yield RecommendationHeader.databaseExit(
      `${acc[0].firstName} ${acc[0].lastName}`
    );
    if (DBA.getAll(recommenHeader)[0].numberOfRecord === 0) {
      yield put(recommendationHeader());
    }

    let recommenLine = yield RecommendationLine.databaseExit();
    if (DBA.getAll(recommenLine)[0].numberOfRecord === 0) {
      yield put(recommendationLine());
    }

    let region = yield Region.databaseExit();
    if (DBA.getAll(region)[0].numberOfRecord === 0) {
      yield put(getRegion());
    }

    let areas = yield Area.databaseExit();
    if (DBA.getAll(areas)[0].numberOfRecord === 0) {
      yield put(getAreas());
    }

    let categories = yield Category.databaseExit();
    if (DBA.getAll(categories)[0].numberOfRecord === 0) {
      yield put(getCategory());
    }

    let imcompatible = yield ImcompatibleProducts.databaseExit();
    if (DBA.getAll(imcompatible)[0].numberOfRecord === 0) {
      yield put(getImcompatibleProduct());
    }

    let mixType = yield MixType.databaseExit();
    if (DBA.getAll(mixType)[0].numberOfRecord === 0) {
      yield put(getMixType());
    }

    yield (stopTime = Date.now());
    if (stopTime - startTime < 1000) {
      yield call(delay, stopTime - startTime);
    }

    let status = yield Status.databaseExit();
    if (DBA.getAll(status)[0].numberOfRecord === 0) {
      yield put(getStatus());
    }

    let labours = yield Labour.databaseExit();
    if (DBA.getAll(labours)[0].numberOfRecord === 0) {
      yield put(getLabour());
    }

    let materialCosts = yield MaterialCosts.databaseExit();
    if (DBA.getAll(materialCosts)[0].numberOfRecord === 0) {
      yield put(getMaterialCosts());
    }

    let packagings = yield Packaging.databaseExit();
    if (DBA.getAll(packagings)[0].numberOfRecord === 0) {
      yield put(getPackaging());
    }

    let config = yield ConfigFile.databaseExit();
    if (DBA.getAll(config)[0].numberOfRecord === 0) {
      yield put(configFile());
    }

    yield call(syncData);
    //  get data success
    yield put(hideSpinner(false));
  } catch (err) {
    // console.log(err);
    yield put(hideSpinner(false));
  }
}*/

function* checkInternet({ pathToGo = "", forceRefresh = false, history = null }) {
  console.log("checkInternet--------------", pathToGo);
  
  // removed cordova specific code, todo, add pwa specific check?
  let connectionOk = yield checkConnection();

  if(! connectionOk) {
    console.log('no connection');
    yield put(hideSpinner(false));
    yield put(getData("showModalErrorSync", true));
      yield put(
        getData(
          "textSyncError",
          "A connection issue has been encountered. Only sync when you have a stable internet connection. Updates will be saved and applied the next time you Sync."
        )
      );
    
      yield history.push(pathToGo ? pathToGo : "/home");
  }
  else
  {
    console.log('about to call sync data');
    yield call(syncData, pathToGo, forceRefresh, history);
  }
  
}

function* syncData(pathToGo, forceRefresh, history) {
  console.log("synData-----------------", pathToGo);

  try {
    // Turn on sync data process
    yield put(getData("flagSyncData", true));

    yield put(hideSpinner(true));
    const acc = yield call(Account.getAll);
    let timeLocal = yield DataGlobalUpdate.getAll(acc[0].email);

    // get data app
    var today = moment(new Date()).format("MM/DD/YYYY, hh:mm:ss A");
    yield put(getData("syncData", today));
    yield put(getData("firstName", acc[0].firstName));
    // get new list client
    let listclient = yield ClientsList.getAll(acc[0].email);
    yield put(getData("listClient", listclient));

    // check role
    var arr = acc[0].profileKey.split(",");
    var areaRole = false;
    var resellerRole = false;
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === "profile_13") {
        areaRole = true;
      }
      if (arr[i] === "profile_26") {
        resellerRole = true;
      }
    }
    var apiRole = "areaManage";
    if (areaRole === false) {
      apiRole = "reseller";
    }

    yield put(getData("roleAccount", apiRole));
    let reseller = yield Account.getAll();
    yield put(
      getData("nutritechMargin", reseller && reseller[0].nutritechMargin)
    );
    yield put(
      getData("resellerMargin", reseller && reseller[0].resellerMargin)
    );

    let contact = yield ConfigFile.findItemByType("Contact Phone");
    console.log(contact);
    if (contact) {
      yield put(
        getData("contactPhone", contact.description)
      );
    }
    // end check role

    // Check sync data
    let checkSyncData = false;

    var today = moment(new Date()).format("MM/DD/YYYY, hh:mm:ss A");

    // Get sync time from server
    const timeServe = yield axios.get(urlSyncData);

    //  If sync time already saved in local
    if (timeLocal[0]) {
      // Check if this time is up-to-date or not
      if (timeLocal[0].lastUpdate !== timeServe.data.records[0].field_281) {
        // Mark sync process
        checkSyncData = true;
        console.log("******** Data is old, Syncing ********");
      } else {
        console.log("******** Data is up-to-date ********");
      }
    } else {
      // Mark sync process
      checkSyncData = true;
      console.log("******** Data is old, Syncing ********");
    }

    let userLogin = yield getLocalStorage("currentUser", false);
    if (_.isEmpty(userLogin) || userLogin !== acc[0].email) {
      removeLocalStorage("currentUser");
      setLocalStorage("currentUser", acc[0].email, false);
      checkSyncData = true;
    }

    //  Sync the new client to server
    // Find the new client added from local but not synced
    let newClient = yield ClientsList.findItem("new", acc[0].email);
    //console.log('Clients', newClient);
    for (let i = 0; i < newClient.length; i++) {
      let data = newClient[i];

      // let region = yield Region.findItem(data.region)
      // data.region = region[0].idRegion

      // If this client still be not sent to server
      if (data.idClient.length == 36) {
        console.log("Add client ", data.id)
        yield axios.post(urlClients[`${apiRole}`], ClientsList.toKnack(data))
          .then(response => {
            ClientsList.update(data.id, 'idClient', response.data.record.id);
            ClientsList.update(data.id, 'updateData', '');
            RecommendationHeader.updateByField('client', data.idClient, 'client', response.data.record.id);
          });
      } else {
        // Else, do update
        console.log("Update client ", data.id)
        yield axios.put(
          `${urlClients[apiRole]}/${data.idClient}`,
          ClientsList.toKnack(data)
        );
      }
    }

    if (newClient.length > 0) {
      // Update sync time to server
      if (timeLocal[0]) {
        yield axios.put(
          `${urlSyncData}/${timeLocal[0].idLastUpdate}`,
          DataGlobalUpdate.toKnack(today)
        );
      }
    }

    // Sync the new recommendation to server

    // Find the new one
    let newRecomendation = yield RecommendationHeader.findItem("new", "update");
    console.log("newRecomendation", newRecomendation);

    for (var j = 0; j < newRecomendation.length; j++) {
      let res = newRecomendation[j];

      // Find data by their id
      let idStatus = yield Status.findItem(res.status);
      let idPackaging = yield Packaging.findItem(res.packaging);

      // Parse value in array to value in object
      res.status = idStatus[0].idStatus;
      res.packaging = idPackaging[0].value;
      res.addedBy = acc[0].idAccount;

      // api recomemdation header
      let idRecommendationHeader = 0;

      console.log(`             Send recommendation headers ${j}  ...`);
      // Send recommendation header
      if (res.updateData == 'new' || res.idRecommendation.length == 36) {
        yield axios
          .post(
            urlRecommendationHeader[`${apiRole}`],
            RecommendationHeader.toKnack(res)
          )
          .then(response => {

            console.log('post new okay', response.data);

            idRecommendationHeader = response.data.record.id;
            RecommendationHeader.update(res.id, 'idRecommendation', response.data.record.id);
            RecommendationHeader.update(res.id, 'idCode', response.data.record.field_107);

           // if(response.data.record.field_213_raw) {
            //  RecommendationHeader.update(res.id, 'pdf', response.data.record.field_213_raw.url);
           // }

            RecommendationHeader.update(res.id, 'updateData', "");
            if (idStatus[0].status == 'Confirmed') {
              Mail.create(response.data.record.id);
            }

           // static async updateByField(fieldWhere, valueWhere, field, value) {
          //  RecommendationLine.updateByField('recommedationHeader', res.idCode, 'idRecommendation', response.data.record.id);
           // RecommendationLine.updateByField('recommedationHeader', res.idCode, 'recommedationHeader', response.data.record.field_107);

           RecommendationLine.updateByField('idRecommendation', res.idRecommendation, 'recommedationHeader', response.data.record.field_107); // must run before next line.
           RecommendationLine.updateByField('idRecommendation', res.idRecommendation, 'idRecommendation', response.data.record.id);
          

          });
      } else if (res.updateData == 'update') {
        yield axios
          .put(
            urlRecommendationHeader[`${apiRole}`] + '/' + res.idRecommendation,
            RecommendationHeader.toKnack(res)
          )
          .then(response => {
            console.log('post update okay', response.data);
            idRecommendationHeader = response.data.record.id;
            RecommendationHeader.update(res.id, 'idRecommendation', response.data.record.id);
            RecommendationHeader.update(res.id, 'idCode', response.data.record.field_107);

           // if(response.data.record.field_213_raw) {
             // RecommendationHeader.update(res.id, 'pdf', response.data.record.field_213_raw.url);
           // }
           
            RecommendationHeader.update(res.id, 'updateData', "");
            if (idStatus[0].status == 'Confirmed') {
              Mail.create(response.data.record.id);
            }
           // RecommendationLine.updateByField('recommedationHeader', res.idCode, 'idRecommendation', response.data.record.id);
           // RecommendationLine.updateByField('recommedationHeader', res.idCode, 'recommedationHeader', response.data.record.field_107);

           RecommendationLine.updateByField('idRecommendation', res.idRecommendation, 'recommedationHeader', response.data.record.field_107); // must run before next line.
           RecommendationLine.updateByField('idRecommendation', res.idRecommendation, 'idRecommendation', response.data.record.id);

          });
      }
    }

    // Find the new one
    let recommendationLines = yield RecommendationLine.getUnSyncedData();
    console.log("Recommendation Lines", recommendationLines);

    // Send recommendation line
    for (var x = 0; x < recommendationLines.length; x++) {
      let dataRecommen = recommendationLines[x];
      // get id product
      let product = yield Product.findItem(dataRecommen.product);
      dataRecommen.product = product[0].idProduct;
      if (dataRecommen.updateData == 'new' || (dataRecommen.idRecommendationLine.length == 36 && dataRecommen.updateData == 'update')) {
        yield axios
          .post(
            urlRecommendationLine[`${apiRole}`],
            RecommendationLine.toKnack(dataRecommen)
          ).then(response => {
            RecommendationLine.update(dataRecommen.id, 'idRecommendationLine', response.data.record.id);
            RecommendationLine.update(dataRecommen.id, 'updateData', '');
          });
      } else if (dataRecommen.updateData == 'update') {
        
        yield axios
          .put(
            urlRecommendationLine[`${apiRole}`] + '/' + dataRecommen.idRecommendationLine,
            RecommendationLine.toKnack(dataRecommen)
          ).then(response => {
            RecommendationLine.update(dataRecommen.id, 'idRecommendationLine', response.data.record.id);
            RecommendationLine.update(dataRecommen.id, 'updateData', '');
          });
      } else if (dataRecommen.updateData == 'delete' && dataRecommen.idRecommendationLine.length != 36) {
        yield axios
          .delete(
            urlRecommendationLine[`${apiRole}`] + '/' + dataRecommen.idRecommendationLine,
            RecommendationLine.toKnack(dataRecommen)
          ).then(response => {
            RecommendationLine.delete(dataRecommen.id);
          });
      }
    }

    // Find the new one
    let mails = yield Mail.getAll();
    console.log("Recommendation Mail", mails);
    for (var x = 0; x < mails.length; x++) {
      let url = `${urlFilePdf}`;
      let id = mails[x].id;
      yield (url = url.replace("xxx", `${mails[x].id}`));

      console.log('about to send mail, calling url', url);
      axios.get(url).then(response => {

        console.log('response from sendMail', response);
        Mail.delete(id);

        if(response.data && response.data.data != null) {
            if(response.data.data.indexOf(".pdf") > -1) {
              console.log('setting header id', id);
              console.log('setting with value', response.data.data);
              let dataJson =  JSON.parse(response.data.data);
              console.log('pdf value', dataJson);

              if(dataJson.field_213_raw) {
                RecommendationHeader.update(id, 'pdf', dataJson.field_213_raw.url);
              }
          }
        }

        if(response.data && response.data.error != null) {
          console.log('error generating pdf', response.data.error);
        }

      });
    }

    // new data
    if (newClient.length > 0 || newRecomendation.length > 0 || recommendationLines.length > 0 || mails.length > 0) {
      checkSyncData = true;
    }

    // ForceRefresh flag is related to http://redmine.hum.co.nz/redmine/issues/12488

    if (checkSyncData || forceRefresh) {
      console.log("      SYNC DATA FROM SERVER TO DEVICE ...");
      yield call(callAllApi);
      //data all done
      // yield call(getTermConditions);
    } else {
      // Turn off flag sync data
      yield put(getData("flagSyncData", false));
      yield put(hideSpinner(false));
    }
    yield history.push(pathToGo ? pathToGo : "/home");
  } catch (error) {
    console.log(error)
    yield put(hideSpinner(false));

    let status = 0;
    if(error.response) {
      if(error.response.status) {
        status = error.response.status
      }
      else
      {
        status = error.response;
      }
    }
  
    // Go to login page if get authen error
    if (status === 401 || status === 403) {
      // Remove token from local storage
      window.localStorage.removeItem("authToken");

      // Remove from header
      axios.defaults.headers.common["Authorization"] = "";
      yield put(getData("showModalErrorSync", true));
      yield put(
        getData(
          "textSyncError",
          "Your token has expired. Please login again to continue. Your data will still be synced after login."
        )
      );
      history.push("/sign-in");
    } else {
      //const acc = yield call(Account.getAll);
      //if (acc.length > 0) {
       // yield DataGlobalUpdate.deleteData(acc[0].email);
      //}
      yield put(getData("showModalErrorSync", true));
      yield put(
        getData(
          "textSyncError",
          "An issue has been encountered. Updates will be saved and applied the next time you Sync."
        )
      );
      yield history.push(pathToGo ? pathToGo : "/home");
    }

  }
}

function* callAllApi() {
  try {
    console.log(`             STARTING  ...`);
    yield put(hideSpinner(true));
    let startTime, stopTime;

    yield (startTime = Date.now());

    const acc = yield call(Account.getAll);

    console.log(`                       Delete all table data  ...`);
    // delete table
    yield all([
      Area.deleteData(),
      Region.deleteData(),
      Category.deleteData(),
      ImcompatibleProducts.deleteData(),
      MixType.deleteData(),
      Packaging.deleteData(),
      Product.deleteData(),
      ResellersList.deleteAll(),
      ClientsList.deleteAll(),
      DataGlobalUpdate.deleteData(acc[0].email),
      RecommendationHeader.deleteAll(),
      RecommendationLine.deleteAll(),
      Status.deleteData(),
      ConfigFile.deleteData(),
      Labour.deleteData(),
      Element.deleteData(),
      MaterialCosts.deleteData(),
      NCRs.deleteData()
    ]);

    console.log(` Call first 7  Items  ...`);
    // first 7 items
    const {
      region,
      area,
      category,
      imcompatible,
      mixType,
      packaging,
      product,
    } = yield all({
      region: axios.get(`${urlRegion}?rows_per_page=1000`),
      area: axios.get(`${urlArea}?rows_per_page=1000`),
      category: axios.get(`${urlCategory}?rows_per_page=1000`),
      imcompatible: axios.get(`${urlImcompatible}?rows_per_page=1000`),
      mixType: axios.get(`${urlMixType}?rows_per_page=1000`),
      packaging: axios.get(`${urlPackaging}?rows_per_page=1000`),
      product: axios.get(`${urlProduct}?rows_per_page=1000`),
    });

    console.log('rate limit delay 1')
    yield delay(1000); // or knack rate limit gets hit

    console.log(` Call 2nd 7 Items  ...`);
    // second 7 items
    const {
      syncData,
      status,
      configFile,
      labour,
      materialCosts,
      element,
      ncr
    } = yield all({
      syncData: axios.get(`${urlSyncData}?rows_per_page=1000`),
      status: axios.get(`${urlStatus}?rows_per_page=1000`),
      configFile: axios.get(`${urlConfigFile}?rows_per_page=1000`),
      labour: axios.get(`${urlLabours}?rows_per_page=1000`),
      materialCosts: axios.get(`${urlMaterialCosts}?rows_per_page=1000`),
      element: axios.get(`${urlElement}?rows_per_page=1000`),
      ncr: axios.get(`${urlNCRs}?rows_per_page=1000`)
    });

    console.log('rate limit delay 2')
    yield delay(1000); // or knack rate limit gets hit

    let arr = acc[0].profileKey.split(",");
    let resellerUser = null;
    if (arr.indexOf('profile_26') >= 0) {
      let resellerUserFilter = [{
        "field": "field_116",
        "operator": "contains",
        "value": encodeURIComponent(acc[0].email)
      }];
      let getUrl = urlResellerUser + '?filters=' + JSON.stringify(resellerUserFilter);
      resellerUser = yield axios.get(getUrl);
    }

    let apiRole = "reseller";
    let markupMinField = "field_210_raw";
    let markupMaxField = "field_239_raw";
    let nutritechMarginField = "field_313_raw";
    let resellerMarginField = "field_315_raw";

    let filter = [{
      "field": "field_208",
      "operator": "contains",
      "value": encodeURIComponent(acc[0].email)
    }];
    if (arr.indexOf('profile_13') >= 0) {
      apiRole = "areaManage";
      filter = [{
        "field": "id",
        "operator": "contains",
        "value": acc[0].idManage
      }];
      markupMinField = "field_237_raw";
      markupMaxField = "field_238_raw";
    }
    if (arr.indexOf('profile_26') >= 0 && resellerUser && resellerUser.data && resellerUser.data.records && resellerUser.data.records.length > 0 && resellerUser.data.records[0].field_235_raw) {
      filter = [{
        "field": "id",
        "operator": "contains",
        "value": resellerUser.data.records[0].field_235_raw[0].id
      }];
    }

    yield put(getData("roleAccount", apiRole));
    let profile = yield axios.get(urlProfile[`${apiRole}`] + '?filters=' + JSON.stringify(filter));

    if (arr.indexOf('profile_26') >= 0) {
      yield Account.updateInfo(
        acc[0].email,
        profile.data.records && profile.data.records.length > 0 ? profile.data.records[0][markupMinField] : '',
        profile.data.records && profile.data.records.length > 0 ? profile.data.records[0][markupMaxField] : '',
        profile.data.records && profile.data.records.length > 0 ? profile.data.records[0][nutritechMarginField] : '',
        profile.data.records && profile.data.records.length > 0 ? profile.data.records[0][resellerMarginField] : '',
      );
    }
    else
    {
      yield Account.updateInfo(
        acc[0].email,
        profile.data.records && profile.data.records.length > 0 ? profile.data.records[0][markupMinField] : '',
        profile.data.records && profile.data.records.length > 0 ? profile.data.records[0][markupMaxField] : '',
        null, null
      );
    }

    //let apiReseller = axios.get(urlResellers[`${apiRole}`]);
    let apiReseller = axios.get(`${urlResellers[`${apiRole}`]}?rows_per_page=1000`);

    let lineFilters = '';

    if(apiRole.toLowerCase() == "reseller") {

       filter = [{
        "field": "field_392",
        "operator": "is",
        "value": resellerUser.data.records[0].field_394_raw
      }];

       lineFilters = '&filters=' + JSON.stringify(filter)
    }

    const {
      reseller,
      client,
      recommendationHeader,
      recommendationLine
    } = yield all({
      reseller: (arr.indexOf('profile_13') >= 0) ? apiReseller : profile,
      client: axios.get(`${urlClients[`${apiRole}`]}?rows_per_page=1000`),
      recommendationHeader: axios.get(`${urlRecommendationHeader[`${apiRole}`]}?rows_per_page=1000`),
      recommendationLine: axios.get(`${urlRecommendationLine[`${apiRole}`]}?rows_per_page=1000` + lineFilters)
    });

    yield put(getClientsSuccess(client, acc[0].email));
    yield put(recommendationHeaderSuccess(recommendationHeader));
    yield put(recommendationLineSuccess(recommendationLine));

    if (client.data.total_pages > 1) {
      for (var i = 2; i <= client.data.total_pages; i++) {
        yield fetchClients(`${urlClients[`${apiRole}`]}?rows_per_page=1000`, i);
      }
    }

    if (recommendationHeader.data.total_pages > 1) {
      for (var i = 2; i <= recommendationHeader.data.total_pages; i++) {
        yield fetchRecommendationHeader(`${urlRecommendationHeader[`${apiRole}`]}?rows_per_page=1000`, i);
      }
    }

    if (recommendationLine.data.total_pages > 1) {
      for (var i = 2; i <= recommendationLine.data.total_pages; i++) {
        yield fetchRecommendationLine(`${urlRecommendationLine[`${apiRole}`]}?rows_per_page=1000` + lineFilters, i);
      }
    }

    if (apiRole == "areaManage") {
      const {
        resellerClient,
        resellerRecommendationHeader,
        resellerRecommendationLine
      } = yield all({
        resellerClient: axios.get(`${urlResellerClient}?rows_per_page=1000`),
        resellerRecommendationHeader: axios.get(`${urlResellerRecommendationHeader}?rows_per_page=1000`),
        resellerRecommendationLine: axios.get(`${urlResellerRecommendationLine}?rows_per_page=1000`)
      });

      yield put(getClientsSuccess(resellerClient, acc[0].email));
      yield put(recommendationHeaderSuccess(resellerRecommendationHeader));
      yield put(recommendationLineSuccess(resellerRecommendationLine));

      if (resellerClient.data.total_pages > 1) {
        for (var i = 2; i <= resellerClient.data.total_pages; i++) {
          yield fetchClients(`${urlResellerClient}?rows_per_page=1000`, i);
        }
      }

      if (resellerRecommendationHeader.data.total_pages > 1) {
        for (var i = 2; i <= resellerRecommendationHeader.data.total_pages; i++) {
          yield fetchRecommendationHeader(`${urlResellerRecommendationHeader}?rows_per_page=1000`, i);
        }
      }

      if (resellerRecommendationLine.data.total_pages > 1) {
        for (var i = 2; i <= resellerRecommendationLine.data.total_pages; i++) {
          yield fetchRecommendationLine(`${urlResellerRecommendationLine}?rows_per_page=1000`, i);
        }
      }
    }

    console.log(`                       Set data to reducer  ...`);


    yield put(getAreaSuccess(area));
    yield put(getRegionSuccess(region));
    yield put(getCategorySuccess(category));
    yield put(getImcompatibleProductSuccess(imcompatible));
    yield put(getMixTypeSuccess(mixType));
    yield put(getPackagingSuccess(packaging));
    yield put(getProductSuccess(product));
    yield put(updateDataSuccess(syncData, acc[0].email));
    yield put(getResellersSuccess(reseller));
    yield put(getNCRsSuccess(ncr));
    yield put(getStatusSuccess(status));
    yield put(configFileSuccess(configFile));
    yield put(getLabourSuccess(labour));
    yield put(getElementSuccess(element));
    yield put(getMaterialCostsSuccess(materialCosts));

    console.log(`                       Update sync time  ...`);
    var today = moment(new Date()).format("MM/DD/YYYY, hh:mm:ss A");
    yield put(getData("syncData", today));
    yield put(getData("firstName", acc[0].firstName));
    // get new list client
    let listclient = yield ClientsList.getAll(acc[0].email);
    yield put(getData("listClient", listclient));

    // Turn off flag sync data
    yield put(getData("flagSyncData", false));
    yield put(hideSpinner(false));
    localStorage.setItem('needsSync',false);
    console.log(`             DONE`);

  } catch (error) {
    //ERROR

    yield put(hideSpinner(false));
  /*  const acc = yield call(Account.getAll);
    if (acc.length > 0) {
      yield DataGlobalUpdate.deleteData(acc[0].email);
    } */
    yield console.log(error);
    yield put(getData("flagSyncData", false));
    // Turn off flag sync data
    yield put(getData("showModalErrorSync", true));
    yield put(
      getData(
        "textSyncError",
        "An issue has been encountered. Updates will be saved and applied the next time you Sync."
      )
    );
  }
}

function* checkUnsyncData(action) {
  try {
    yield ClientsList.deleteUnsyncData();
    yield RecommendationHeader.deleteUnsyncData();
    yield RecommendationLine.deleteUnsyncData();
    let recomendationHeader = yield RecommendationHeader.findItemOld();
    //console.log("recomendationHeader", recomendationHeader);
    if (recomendationHeader.length > 0) {
      for (let d = 0; d < recomendationHeader.length; d++) {
        RecommendationHeader.loseDelete(
          recomendationHeader[d].idRecommendation
        );
        RecommendationLine.loseDelete(recomendationHeader[d].idCode);
      }
    }
    action.resolve(true);
    yield put(hideSpinner(false));
  } catch (err) {
    yield put(getData("modelSyncError", true));
    yield put(getData("textSyncError", err.message));
    yield put(hideSpinner(false));
  }
}

function* getTermConditions() {
  try {
    // Call API to get Rep and save Rep information to sqlite
    const rep = yield ConfigFile.findTerms();

    const [{ text = "" }] = rep || [{ text: "" }]; // content is first item in array return
    yield put(saveTermConditions(text));
  } catch (err) {
    // Some error happens
    console.log(err);
    yield put(saveTermConditions(""));
  }
}

function* searchProduct({ params, resolve, reject }) {
  try {
    // Call API to get Rep and save Rep information to sqlite

    const result = yield call(Product.filterByConditionLike, params);
    console.log("result--------------------", result);
    resolve(result);
  } catch (err) {
    resolve([]);
  }
}

function* getDiscount(data) {
  const { reject, resolve } = data.value;
  try {
    const acc = yield call(Account.getAll);
    resolve(acc[0]);
  } catch (err) {
    console.log(err);
    reject(err)
  }
}

function* saga() {
  yield takeLatest(FETCH_ALL, callAllApi);
  //yield takeLatest(GET_REGIONS, fetchRegions);
  //yield takeLatest(GET_AREAS, fetchAreas);
  //yield takeLatest(GET_CATEGORY, fetchCategories);
  //yield takeLatest(GET_ELEMENT, fetchElement);
  //yield takeLatest(GET_IMCOMPATIBLE_PRODUCT, fetchImcompatibleProducts);
  //yield takeLatest(GET_MIX_TYPE, fetchMixType);
  //yield takeLatest(GET_NCR, fetchNCRs);
  //yield takeLatest(GET_PACKAGING, fetchPackaging);
  //yield takeLatest(GET_PRODUCT, fetchProduct);
  //yield takeLatest(GET_STATUS, fetchStatus);
  yield takeLatest(GET_CLIENTS, fetchClients);
  //yield takeLatest(GET_RESELLERS, fetchResellers);
  //yield takeLatest(CONFIG_FILE, fetchConfigFile);
  //yield takeLatest(UPDATE_DATA, fetchSyncData);
  //yield takeLatest(RECOMMENDAION_HEADER, fetchRecommendationHeader);
  //yield takeLatest(RECOMMENDAION_LINE, fetchRecommendationLine);
  yield takeLatest(SYNC_DATA, checkInternet);
  yield takeLatest(LOG_OUT, logOut);
  yield takeLatest(CHECK_UNSYNC_DATA, checkUnsyncData);
  //yield takeLatest(RESUME_APP, resumeApp);
  yield takeLatest(GET_TERM_CONDITIONS, getTermConditions);
  yield takeLatest(SEARCH_PRODUCT, searchProduct);
  yield takeLatest(GET_DISCOUNT, getDiscount);
}

export default saga;
