export const name = 'Packaging';
export const fields = {
  id: 'id',
  idPackaging: 'value',
  packaging: 'label',
  mixType: 'mixType',
  cost: 'cost',
  size: 'size',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idPackaging, type: 'text' },
    { name: fields.packaging, type: 'text' },
    { name: fields.mixType, type: 'text' },
    { name: fields.cost, type: 'text' }, //changed from number during port since data coming from knack is string.
    { name: fields.size, type: 'number' },
  ],
  pk: [fields.id],
};
export default schema;
