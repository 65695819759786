import { merge } from "lodash";
import { fields, name } from "./schema";
import dba from "utils/dba";
import {createUUID} from "../../utils/helpers";

export default class ClientsList {

  static async getAll(value) {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    return dba.getAll(result);
  }

  static async getAreaManagerClient() {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE reseller =''`);
    return dba.getAll(result);
  }

  static async getResellerClient() {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE reseller !=''`);
    return dba.getAll(result);
  }

  static async deleteAll() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async databaseExit(value) {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name} WHERE ${fields.manageBy}=${value}'`);
  }

  static async findItem(value1, value2) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.updateData}='${value1}' && ${fields.manageBy}='${value2}'`);
    result = dba.getAll(result);
    return result;
  }

  static async deleteUnsyncData(value1, value2) {
    let result = await dba.execute(`DELETE  FROM ${name} WHERE ${fields.updateData}='new' || ${fields.updateData}='delete'`);
    result = dba.getAll(result);
    return result;
  }

  static async findClient(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idClient}='${value}'`);
    result = dba.getAll(result);
    return result;
  }

  static async update(id, field, value) {
    return dba.execute(`UPDATE ${name} SET ${field} = '${value}' WHERE id = '${id}'`);
  }

  static async databaseUpdate(value) {

      const sql = `UPDATE ${name} SET 
      ${fields.area} = '@area',
      ${fields.firstName} = '@firstName',
      ${fields.lastName} = '@lastName',
      ${fields.name} = '@name',
      ${fields.farmName} = '@farmName',
      ${fields.customerCode} = '@customerCode',
      ${fields.address} = '@address',
      ${fields.suburb} = '@suburb',
      ${fields.townCity} = '@townCity',
      ${fields.region} = '@region',
      ${fields.postCode} = ${parseInt(value.data.postCode.toString())},
      ${fields.rd} = '@rd',
      ${fields.email} = '@email',
      ${fields.phone} = '@phone',
      ${fields.updateData} = 'new'
        WHERE id='${value.id}'`

      let mappings = [
        {
          key: "@area",
          value: value.data.area
        },
        {
          key: "@firstName",
          value: value.data.firstName
        },
        {
          key: "@lastName",
          value: value.data.lastName
        },
        {
          key: "@name",
          value: value.data.firstName + ' ' + value.data.lastName
        },
        {
          key: "@farmName",
          value: value.data.farmName
        },
        {
          key: "@customerCode",
          value: value.data.customerCode
        },
        {
          key: "@address",
          value: value.data.address
        },
        {
          key: "@suburb",
          value: value.data.suburb
        },
        {
          key: "@townCity",
          value: value.data.townCity
        },
        {
          key: "@region",
          value: value.data.region
        },
        {
          key: "@rd",
          value: value.data.rd
        },
        {
          key: "@email",
          value: value.data.email
        },
        {
          key: "@phone",
          value: value.data.phone
        },
      ]

      return dba.executeQryMapped(sql, mappings);
  }

  static async deleteData(value) {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.manageBy} ='${value}' `);
  }

  static async create(data) {

    if(Array.isArray(data)) {
      const bulkSql = `INSERT INTO ${name} VALUES='@values'`;
      return dba.executeQry(bulkSql, data);
    }
    else
    {
      const sql = `INSERT INTO ${name} VALUES='@values'`;
      return dba.executeQry(sql, [data], true);
    }
    
  }

  static toItem(data, extension) {

    let id = createUUID();

    let result = {
      [fields.id]:data.id ? data.id : id,
      [fields.idClient]:data.id ? data.id : id,
      [fields.areaManager]: data['field_100_raw'] && data['field_100_raw'][0] ? data['field_100_raw'][0].id : '', 
      [fields.reseller]:data['field_211_raw'] && data['field_211_raw'][0] ? data['field_211_raw'][0].id : '', 
      [fields.area]:data['field_199_raw'] && data['field_199_raw'][0] ? data['field_199_raw'][0].id : '', 
      [fields.firstName]:data['field_89'], 
      [fields.lastName]:data['field_236'], 
      [fields.name]:data['field_89'] + ' ' + data['field_236'],
      [fields.farmName]:data['field_90'], 
      [fields.customerCode]:data['field_91'], // maps to dairy supply number in knack?
      [fields.address]:data['field_92'], 
      [fields.suburb]:data['field_93'], 
      [fields.townCity]:data['field_94'], 
      [fields.region]:data['field_201_raw'][0] ? data['field_201_raw'][0].id : '', 
      [fields.postCode]:data['field_95'], 
      [fields.rd]:data['field_96'] ? data['field_96'] : '', 
      [fields.email]:data['field_97_raw'].email, 
      [fields.phone]:data['field_98_raw'].number,
      [fields.manageBy]:extension,
      [fields.updateData]:''}
    //if (extension) {
    //  console.log('result before merge for ext:' + extension, result);
     // result = merge(result, extension);
     // console.log('result after merge', result);
    //}
    return result;
  }
  static toKnack(data) {
    let result = {};
    result['field_100'] = [{ 'id': `${data.areaManager}` }];
    result['field_211'] = [{ 'id': `${data.reseller}` }];
    result['field_199'] = [{ 'id': `${data.area}` }];
    result['field_89'] = `${data.firstName}`;
    result['field_236'] = `${data.lastName}`;
    result['field_90'] = `${data.farmName}`;
    result['field_91'] = `${data.customerCode}`;
    result['field_92'] = `${data.address}`;
    result['field_93'] = `${data.suburb}`;
    result['field_94'] = `${data.townCity}`;
    result['field_201'] = [{ 'id': `${data.region}` }];
    result['field_95'] = parseInt(data.postCode.toString());
    result['field_96'] = `${data.rd}`;
    result['field_97'] = { 'email': `${data.email}` };
    result['field_98'] = `${data.phone}`;
    return result;
  }

  static toInsert(data) {
    
    let id = createUUID();
   
    /*let result = `(
      "${data.id ? data.id : id}",
      "${data.idClient ? data.idClient : id}",
      "${data.areaManager}",
      "${data.reseller}",
      "${data.area}",
      "${data.firstName}",
      "${data.lastName}",
      "${data.firstName} ${data.lastName}",
      "${data.farmName}",
      "${data.customerCode}",
      "${data.address}",
      "${data.suburb}",
      "${data.townCity}",
      "${data.region}",
      ${data.postCode},
      "${data.rd}",
      "${data.email}",
      "${data.phone}",
      "${data.manageBy}",
      "new"
    )`*/

    let result = {
      [fields.id]:data.id ? data.id : id,
      [fields.idClient]:data.id ? data.idClient : id,
      [fields.areaManager]: data.areaManager, 
      [fields.reseller]:data.reseller, 
      [fields.area]:data.area, 
      [fields.firstName]:data.firstName, 
      [fields.lastName]:data.lastName, 
      [fields.name]:data.firstName + ' ' + data.lastName,
      [fields.farmName]:data.farmName, 
      [fields.customerCode]:data.customerCode, // maps to dairy supply number in knack?
      [fields.address]:data.address, 
      [fields.suburb]:data.suburb, 
      [fields.townCity]:data.townCity, 
      [fields.region]:data.region, 
      [fields.postCode]:parseInt(data.postCode.toString()), 
      [fields.rd]:data.rd, 
      [fields.email]:data.email, 
      [fields.phone]:data.phone,
      [fields.manageBy]:data.manageBy,
      [fields.updateData]:'new'}

    return result;
  }
}
