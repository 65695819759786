/**
 *
 * SpinnerModal
 *
 */

import React from 'react';
import Modal from 'react-bootstrap4-modal';
import Spinner from 'react-spinkit';
import './style.scss';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';

function SpinnerModal(props) {
  const { visible, className, dialogClassName, message } = props;
  return (
    <Modal
      visible={visible}
      className={`spinner-modal ${className}`}
      dialogClassName={`modal-dialog-centered ${dialogClassName}`}
    >
      <div className="modal-body">
        <div className="d-flex justify-content-center">
          <Spinner
            name="circle"
            className="spinner"
            color="white"
          />
        </div>
        <div className="d-flex justify-content-center text-white" style={{textAlign:'center'}}>
         
         {message != null ? 
         <>{message}</> 
         :
          <>
          Syncing. Please wait... 
          <br />Do not leave app until completed.
          </>
          }
        </div>
      </div>
    </Modal>
  );
}

SpinnerModal.propTypes = {};

export default SpinnerModal;
