// cra imports
import React from 'react';
//import ReactDOM from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as JsStore from 'jsstore';
import SqlWeb from "sqlweb";

// imports ported from old app
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import {createHashHistory} from 'history'; //todo, changed this line when porting to get past compile error without understanding consequences.
import LogRocket from 'logrocket'; //todo add log rocket? note LogRocket.init code not included below.
import 'sanitize.css';


// Import Language Provider
//import LanguageProvider from 'containers/LanguageProvider';

// Import CSS Libraries
import 'css/icon/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import GlobalStyle from 'global-styles';
import 'css/style.scss';

import configureStore from './configureStore';
import config from 'configApp';

//import '!file-loader?name=[name].[ext]!./images/screen/ios/handheld-portrait-320x480.png'; //todo, import pwa icons, etc?

//todo, changed when porting from ConnectedRouter.... do we need to do anything else to connect to redux. is hash router correct?
//import {
 // HashRouter as Router,
//} from "react-router-dom";


import { DB_CONFIG } from './models';
import App from 'containers/App';

LogRocket.init(config.logRocketAppId);

if (process.env.NODE_ENV === 'production') {
 // console.log('Console.log is disabled due to environment.')
  //console.log = function() {};
}
console.log('Build type:', process.env.NODE_ENV);
console.log('Verbose logging:', process.env.REACT_APP_VERBOSE_LOGGING); // note, this is string.

// Create redux store with history
const initialState = {};
const history = createHashHistory();
const store = configureStore(initialState, history);

//const root = ReactDOM.createRoot(document.getElementById('app'));
const MOUNT_NODE = document.getElementById('app');
 
if(process.env.REACT_APP_BRANCH_NAME != 'live') {
  document.title = config.environment + ' - ' + document.title;
}

//const workerPath = require("jsstore/dist/jsstore.worker.js");

  ReactDOM.render(
    <React.StrictMode>
       
      <GlobalStyle />
      <Provider store={store}>
      
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        
      </Provider>
    </React.StrictMode>,MOUNT_NODE,
  );


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

/*///////////////////////////////////////////////////////////////
/////                START INIT DB AND TABLE                /////
///////////////////////////////////////////////////////////////*/

const getWorkerPath = () => {

  // return dev build when env is development
  if (process.env.NODE_ENV === 'development') {

      return "jsstore.worker.js";

  }
  else { // return prod build when env is production

      return "jsstore.worker.min.js";
  }
};

async function initDb()
{
   /* eslint-disable-next-line*/
  db = new JsStore.Connection(new Worker(getWorkerPath()));
  /* eslint-disable-next-line*/
  db.addPlugin(SqlWeb);

  var tablesSchemaConvertedForStoreJS = getTables();

  if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
    console.log('tablesSchemaConvertedForStoreJS', tablesSchemaConvertedForStoreJS);
  }

  /* eslint-disable-next-line*/
  const isDbCreated = await db.initDb({
    name: 'nutritechdb',
    tables: tablesSchemaConvertedForStoreJS
  });

  console.log('isDbCreated',isDbCreated);
}

function getTables() {

  let tables = [];

  // Create available table
  DB_CONFIG.tables.forEach(async (table, index) => {
    let columnsForTable = {};

    table.columns.forEach(column => {
     // let sqlStr = `${column.name} ${column.type}`;
     // if (column.constraints && column.constraints.length > 0) {
      //  sqlStr = `${sqlStr} ${column.constraints.join(' ')}`;
      //}

      let colType = column.type.replace(' PRIMARY KEY','');
      colType = colType.replace(' AUTOINCREMENT','')
     /* String - 'string'
        Number - 'number'
        DateTime - 'date_time' . The value should be date object i.e value = new Date()
        Object - 'object'
        Array - 'array'
        Boolean - 'boolean'*/

      if(colType == 'text') {
        colType = 'string';
      } 

      if(colType == 'integer') {
        colType = 'number';
      } 

      if(colType == 'real') {
        colType = 'number';
      } 

      if(table.pk == column.name) {
        
        if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
          console.log('is auto' + table.name + ' pk:' + table.pk, column.type.indexOf('AUTOINCREMENT') > -1);
        }

        columnsForTable[column.name] = {
          primaryKey: true,
         // dataType: colType, don't set dataType on auto
          autoIncrement: column.type.indexOf('AUTOINCREMENT') > -1
        }
      }
      else
      {
        columnsForTable[column.name] = {
          dataType: colType,
        }
      }
    });

    tables.push({
      name: table.name,
      columns: columnsForTable
    })
  });

  return tables;
}

initDb();

// Use browser sqlite

/*function initDb() {
  // Create available table
  DB_CONFIG.tables.forEach(async (table, index) => {
    let columns = [];

    table.columns.forEach(column => {
      let sqlStr = `${column.name} ${column.type}`;
      if (column.constraints && column.constraints.length > 0) {
        sqlStr = `${sqlStr} ${column.constraints.join(' ')}`;
      }
      columns.push(sqlStr);
    });

    let sql = `CREATE TABLE IF NOT EXISTS ${table.name} (${columns}) `;
    // Execute sql query
    try {
      /* eslint-disable-next-line 
      let res = await cordovaSQLite.execute(db, sql);
    } catch (err) {
      console.log(err);
    }
  });
}*/

//todo there was onBack related cordova code in original app code. is this something to handle on pwa?


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
