/*
 *
 * SignInPage actions
 *
 */
import { createAction } from 'redux-actions';
import { DEFAULT_ACTION, HANDLE_CHANGE_INPUT, MODAL_ERROR,
    LOGIN, LOGIN_SUCCESS, LOGIN_ERROR } from "./constants";

export const login = createAction(LOGIN);
export const loginSuccess = createAction(LOGIN_SUCCESS);
export const loginError = createAction(LOGIN_ERROR);

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}

export function handleChangeInput(key,value) {
    return {
        type: HANDLE_CHANGE_INPUT,
        key,
        value
    };
}
export function modalError(event) {
    return {
        type: MODAL_ERROR,
        event
    };
}
