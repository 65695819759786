export const name = "Product";
export const fields = {
  id: "id",
  idProduct: "idProduct",
  category: "category",
  element: "element",
  productName: "productName",
  autoAdd: "autoAdd",
  elementalCalculation: "elementalCalculation",
  dosePerCow: "dosePerCow",
  dosePerCowUnit: "dosePerCowUnit",
  gramsPesBatch: "gramsPesBatch",
  costPerBatch: "costPerBatch",
  unit: "unit",
  ncr: "ncr",
  mixType: "mixType",
  costPer: "costPer",
  ingredient: "ingredient",
  balancingAgent: "balancingAgent",
  defaultValue: "defaultValue"
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: "integer PRIMARY KEY  AUTOINCREMENT" },
    { name: fields.idProduct, type: "text" },
    { name: fields.category, type: "text" },
    { name: fields.element, type: "text" },
    { name: fields.productName, type: "text" },
    { name: fields.autoAdd, type: "text" },
    { name: fields.elementalCalculation, type: "number" },
    { name: fields.dosePerCow, type: "number" },
    { name: fields.dosePerCowUnit, type: "text" },
    { name: fields.gramsPesBatch, type: "number" },
    { name: fields.costPerBatch, type: "number" },
    { name: fields.unit, type: "text" },
    { name: fields.ncr, type: "text" },
    { name: fields.mixType, type: "text" },
    { name: fields.costPer, type: "text" },
    { name: fields.ingredient, type: "text" },
    { name: fields.balancingAgent, type: "text" },
    { name: fields.defaultValue, type: "number" }
  ],
  pk: [fields.id]
};
export default schema;
