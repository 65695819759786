import { merge } from "lodash";
import dba from "utils/dba";
import { fields, name } from "./schema";

export default class Element {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }


  static async create(data) {

    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);

  }

  static toItem(data) {
 
    let result = {
      [fields.id]:data.id,
      [fields.category]:data["field_327_raw"][0] ? data["field_327_raw"][0].identifier : "",
      [fields.categoryId]:data["field_327_raw"][0] ? data["field_327_raw"][0].id : "",
      [fields.element]:data["field_328_raw"][0] ? data["field_328_raw"][0].identifier : "",
      [fields.elementId]:data["field_328_raw"][0] ? data["field_328_raw"][0].id : "",
    }

    return result;
  }

}
