/*
 *
 * SignInPage constants
 *
 */

export const DEFAULT_ACTION = "app/SignInPage/DEFAULT_ACTION";
export const HANDLE_CHANGE_INPUT = "app/SignInPage/HANDLE_CHANGE_INPUT";
export const MODAL_ERROR = "app/SignInPage/MODAL_ERROR";

export const LOGIN = "app/SignInPage/LOGIN";
export const LOGIN_SUCCESS = "app/SignInPage/LOGIN_SUCCESS";
export const LOGIN_ERROR = "app/SignInPage/LOGIN_ERROR";
