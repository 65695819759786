export const name = 'NCRs';
export const fields = {
  id: 'id',
  idNcr: 'idNcr',
  ncr: 'ncr',
  lowerLimit: 'lowerLimit',
  upperLimit: 'upperLimit',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idNcr, type: 'text' },
    { name: fields.ncr, type: 'text' },
    { name: fields.lowerLimit, type: 'number' },
    { name: fields.upperLimit, type: 'number' },
  ],
  pk: [fields.id],
};
export default schema;
