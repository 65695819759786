export const name = 'Mail';
export const fields = {
  id: 'id'
};

const schema = {
  name,
  columns: [
    { name: fields.id, type: 'text' }
  ],
  pk: [fields.id]
};
export default schema;
