import { createSelector } from "reselect";
import { initialState } from "./reducers";
import makeSelectSignInPage from "../SignInPage/selectors";

const selectGlobalData = state => state.get("global", initialState);

// const selectGlobal = state => state.get('global');
export const globalData = () =>
  createSelector(selectGlobalData, state => state.toJS());

export default globalData;
