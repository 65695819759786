/*
 *
 * TermsConditions actions
 *
 */

import { CHECK_TERMS_CONDITIONS, DEFAULT_ACTION } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}
export function checkTermsConditions(value) {
  return {
    type: CHECK_TERMS_CONDITIONS,
    value
  };
}
