import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';

export default class ConfigFile {

  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idConfigType}='${value}'`);
    result = dba.getAll(result);
    return result;
  }

  static async findEmailBody() {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.account}='EmailBody'`);
    result = dba.getAll(result);
    return result;
  }

  static async findTerms() {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.configType}='TERMS'`);
    result = dba.getAll(result);
    return result;
  }

  static async findItemByType(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.configType}='${value}'`);
    result = dba.getAll(result);
    if (result.length > 0) {
      return result[0];
    }
    return false;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data, extension) {
 
    let result = {
      [fields.idConfigType]:data.id,
      [fields.configType]:data['field_227'],
      [fields.account]:data['field_226'],
      [fields.description]:data['field_228'],
      [fields.key]:data['field_229'],
      [fields.status]:data['field_230'],
      [fields.text]:data['field_322']
    }

    return result;
  }

}
