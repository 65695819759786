/*
 *
 * SignInPage reducer
 *
 */

import { fromJS } from "immutable";
import {DEFAULT_ACTION, HANDLE_CHANGE_INPUT, MODAL_ERROR, LOGIN, LOGIN_ERROR, LOGIN_SUCCESS} from "./constants";
import { LOCATION_CHANGE } from 'react-router-redux';

export const initialState = fromJS({
    email: '',
    password: '',
    modal_error: false,
    modal_content: '',
    isOnLogin: false,
});

function signInPageReducer(state = initialState, action) {
    switch (action.type) {
        case DEFAULT_ACTION:
            return state;

        case HANDLE_CHANGE_INPUT:
            return state.set(action.key,action.value);

        case MODAL_ERROR:
            return state.setIn(action.event.key,action.event.value);

        case LOGIN:
            return state.set('isOnLogin', true);

        case LOGIN_SUCCESS:
            return state.set('isOnLogin', false);

        case LOGIN_ERROR:
            return state.set('isOnLogin', false);

        case LOCATION_CHANGE:
          return initialState;

        default:
            return state;
    }
}

export default signInPageReducer;
