import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class Status {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.status}=\'${value}\'`);
    result = dba.getAll(result);
    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data) {
 
    let result = {
      [fields.idStatus]:data.id,
      [fields.status]:data['field_214']
    }

    return result;
  }
}
