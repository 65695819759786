export const name = 'Status';
export const fields = {
  id: 'id',
  idStatus: 'idStatus',
  status: 'status',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idStatus, type: 'text' },
    { name: fields.status, type: 'text' }
  ],
  pk: [fields.id],
};
export default schema;
