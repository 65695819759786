import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class DataGlobalUpdate {
  static async getAll(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.manageBy}=\'${value}\'`);
    result = dba.getAll(result);
    return result;
  }
  
  static async databaseExit(value) {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name} WHERE ${fields.manageBy}=\'${value}\'`);
  }

  static async deleteData(value) {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.manageBy}=\'${value}\'`);
  }

  static toKnack(data) {
    let result = {};
    result['field_281'] =  `${data}`;
    return result;
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data, extension) {
 
    let result = {
      [fields.idLastUpdate]:data.id,
      [fields.lastUpdate]:data['field_281'],
      [fields.manageBy]:extension
    }

    return result;
  }
}
