import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class Region {

  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.nameRegion}='${value}'`);
    result = dba.getAll(result);
    return result;
  }

  static async findRegion(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idRegion}='${value}'`);
    result = dba.getAll(result);
    return result;
  }

  static async create(data) {

    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);

  }

  static toItem(data) {
 
    let result = {
      [fields.idRegion]:data.id,
      [fields.nameRegion]:data['field_202'],
      [fields.sequence]:data['field_232'],
    }

    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

}
