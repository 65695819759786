/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */
import axios from "axios";

import SpinnerModal from "components/SpinnerModal";
import config from "configApp";
import CategoryPage from "containers/CategoryPage/Loadable";
import ClientCreate from "containers/ClientCreate/Loadable";
import ClientDetail from "containers/ClientDetail/Loadable";
import ClientList from "containers/ClientListPage/Loadable";
import ElementsPage from "containers/ElementsPage/Loadable";
// import HomePage from 'containers/HomePage/Loadable';
import HomePage from "containers/HomePage";
import ManageRecommendations from "containers/ManageRecommendations/Loadable";
import NewRecommendation from "containers/NewRecommendation/Loadable";
import ProductPage from "containers/ProductPage/Loadable";
import RecommendationCreate from "containers/RecommendationCreate/Loadable";
import RecommendationPage from "containers/RecommendationPage/Loadable";
import RecommendationSent from "containers/RecommendationSent/Loadable";
import RecommendationsList from "containers/RecommendationsList/Loadable";
import RecommendationView from "containers/RecommendationView/Loadable";
import ResellerListRecommendation from "containers/ResellerListRecommendation/Loadable";
import ResellerPage from "containers/ResellerPage/Loadable";
import ResellerRecommendations from "containers/ResellerRecommendationsPage/Loadable";
// container
//nutritech
import SignInPage from "containers/SignInPage/Loadable";
import TermsConditions from "containers/TermsConditions/Loadable";
// library
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";
import { getLocalStorage, setAuthToken } from "utils/helpers";
// saga,reducer,...
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import WarningModal from "../../components/WarningModal";
import { getData, hideSpinner, syncData } from "../App/actions";
import reducer from "../App/reducers";
import saga from "../App/saga";
import globalData from "./selectors";

//todo, during port replaced withRouter with following: https://stackoverflow.com/questions/66465750/withrouter-is-not-exported-from-react-router-dom

/*function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }
}*/
//

const withReducer = injectReducer({ key: "global", reducer });
const withSaga = injectSaga({ key: "global", saga });

const mapStateToProps = createStructuredSelector({
  globalData: globalData()
});
const mapDispatchToProps = dispatch => {
  return {
    syncData: (pathToGo,forceRefresh, history) => {
      dispatch(syncData(pathToGo,forceRefresh, history));
    },
    hideSpinner: value => {
      dispatch(hideSpinner(value));
    },
    getData: (key, value) => {
      dispatch(getData(key, value));
    }
  };
};
const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export class App extends React.Component {
  constructor(props) {
    super(props);
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  handleBeforeUnload(event) {
    const confirmationMessage = 'You may have unsaved changes.'; // only some browsers will also display this.
    event.preventDefault();
    event.returnValue = confirmationMessage; // most browsers display 'Leave Site? Changes you made may not be saved.'
    return confirmationMessage; 
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentDidMount() {
    // commented out on purpose. on resume sync removed in 1.0.46
    //document.addEventListener("resume", this.onCallModel, false);
    
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  componentWillMount = () => {
    // Prepare the token first
    this.prepareToken();

    // auto login
    if (
      window.localStorage.getItem("authToken")
    ) {
      console.log('auth token found');
      setAuthToken(window.localStorage.getItem("authToken"));
      // axios.defaults.headers.common['Authorization'] = window.localStorage.getItem('authToken');
      // axios.defaults.headers.common['X-Knack-Application-Id'] = config.knack.appId;
      let accept = getLocalStorage("accept", false);
      accept === "true"
        ? this.props.history.push("/home")
        : this.props.history.push("/terms-conditions");
        
      // this.props.syncData(accept === "true" ? "/home" : "/terms-conditions");
    }
    else
    {
      console.log('no token found');
      if (
        this.props.history.location.pathname != "/sign-in" &&
        this.props.history.location.pathname != "/"
      ) {
        window.location.href = "/";
      }
    }
  };


  prepareToken = () => {
    // Automatically add token to request if token existed
    axios.interceptors.request.use(
      axiosConfig => {
        if (!axiosConfig.headers.Authorization) {
          const token = window.localStorage.getItem("authToken");
          if (token !== null && token !== "" && token !== undefined) {
            axiosConfig.headers.Authorization = window.localStorage.getItem(
              "authToken"
            );
            axiosConfig.headers["X-Knack-Application-Id"] = config.knack.appId;
            axiosConfig.headers["X-Knack-REST-API-KEY"] = "knack";
          } else {
            axiosConfig.headers["X-Knack-Application-Id"] = config.knack.appId;
            axiosConfig.headers["X-Knack-REST-API-KEY"] = "knack";
          }
        }

        return axiosConfig;
      },
      error => Promise.reject(error)
    );

    // Auto bring user back to sign in page if auth error happen
    axios.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error && error.response) {
          let { status = 0 } = error.response;
          // Go to login page if get authen error
          if (status === 401 || status === 403) {
            // Remove token from local storage
            window.localStorage.removeItem("authToken");

            // Remove from header
            axios.defaults.headers.common["Authorization"] = "";
            this.props.history.replace("/sign-in");
          }
        }
        return Promise.reject(error);
      }
    );
  };

  onSyncData = () => {
    this.props.hideSpinner(true);
    let accept = getLocalStorage("accept", false);
    this.props.syncData(accept === "true" ? "/home" : "/terms-conditions", false, this.props.history);
    this.props.getData("modelSyncData", false);
    this.props.getData("modelSyncError", false);
    this.props.getData("showModalErrorSync", false);
  };

  render() {
    return (
      <>
      <div className='environment'>{config.environmentWarning}</div>

        <Switch>
          <Route path="/sign-in" component={SignInPage} />
          <Route path="/home" component={HomePage} />
          <Route path="/new-recommendation" component={NewRecommendation} />
          <Route
            path="/manage-recommendations"
            component={ManageRecommendations}
          />
          <Route
            path="/recommendation-create"
            component={RecommendationCreate}
          />
          <Route path="/category" component={CategoryPage} />
          <Route path="/product" component={ProductPage} />
          <Route path="/elements" component={ElementsPage} />
          <Route path="/recommendation" component={RecommendationPage} />
          <Route path="/recommendation-view" component={RecommendationView} />
          <Route path="/terms-conditions" component={TermsConditions} />
          <Route
            path="/reseller-recommendations"
            component={ResellerRecommendations}
          />
          <Route
            path="/reseller-detail"
            component={ResellerListRecommendation}
          />
          <Route path="/reseller" component={ResellerPage} />
          <Route path="/recommendations-list" component={RecommendationsList} />
          <Route path="/recommendation-sent" component={RecommendationSent} />
          <Route path="/clients" component={ClientList} />
          <Route path="/clients-list" component={ClientList} />
          <Route path="/client-detail" component={ClientDetail} />
          <Route path="/client-create" component={ClientCreate} />
          <Route path="/" component={SignInPage} />
        </Switch>

        <SpinnerModal visible={this.props.globalData.spinner} />
        <WarningModal
          visible={this.props.globalData.modelSyncData}
          title="SYNC DATA"
          content={"You should sync data to update data for app."}
          okText={"SYNC DATA"}
          onOkClick={this.onSyncData}
        />
        <WarningModal
          visible={this.props.globalData.modelSyncError}
          title="SYNC DATA ERROR"
          content={this.props.globalData.textSyncError}
          okText={"SYNC DATA"}
          onOkClick={this.onSyncData}
          cancelText={"CLOSE"}
          onCancelClick={() => this.props.getData("modelSyncError", false)}
        />
        <WarningModal
          visible={this.props.globalData.showModalErrorSync}
          title="SYNC DATA ERROR"
          content={this.props.globalData.textSyncError}
          okText={"Continue"}
          onOkClick={() => {
            this.props.getData("showModalErrorSync", false);
            //this.onSyncData();
          }}
          onCancelClick={() => this.props.getData("showModalErrorSync", false)}
        />
        <WarningModal
          visible={this.props.globalData.connection}
          title="NO INTERNET CONNECTION"
          content={
            "Can not Sync currently. Please try again when you have an Internet connection"
          }
          okText={"Close"}
          onOkClick={() => this.props.getData("connection", false)}
        />
      </>
    );
  }
}

export default compose(
  withReducer,
  withSaga,
  withRouter,
  withConnect
)(App);
