export const name = 'ResellersList';
export const fields = {
  id: 'id',
  idManageReseller: 'idManageReseller',
  manageUser: 'manageUser',
  name: 'name',
  contactName: 'contactName',
  email: 'email',
  phone: 'phone',
  markupMin: 'markupMin',
  markupMax: 'markupMax',
  contact: 'contact',
  clientDisplay: 'clientDisplay',
  resellerMargin: 'resellerMargin',
  nutritechMargin: 'nutritechMargin',
  manageName: 'manageName',
  areaManager: 'areaManager'
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idManageReseller, type: 'text' },
    { name: fields.manageUser, type: 'text' },
    { name: fields.name, type: 'text' },
    { name: fields.contactName, type: 'text' },
    { name: fields.email, type: 'text' },
    { name: fields.phone, type: 'text' },
    { name: fields.markupMin, type: 'text' },
    { name: fields.markupMax, type: 'text' },
    { name: fields.contact, type: 'number' },  //changed from text as value coming from knack is number
    { name: fields.clientDisplay, type: 'text' },
    { name: fields.resellerMargin, type: 'text' },
    { name: fields.nutritechMargin, type: 'text' },
    { name: fields.manageName, type: 'text' },
    { name: fields.areaManager, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
