/*
 *
 * NewRecommendation actions
 *
 */

import {
  DEFAULT_ACTION, GET_DATA_CLIENT, GET_DATA_RECOMMENDAION, GET_RECOMMENDATION_LIST,
  MODAL, DROPDOWN, RESET,
  PUSH_DATA, PUSH_DATA_SUCCESS, GET_DATA_ELEMENT, DELETE_RECOMMENDAION, GET_RECOMMENDATION, GET_DATA,
  GET_DISCOUNT, GET_DATA_PRODUCT
} from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}
export function getData(value) {
  return {
    type: GET_DATA, value
  };
}
export function getDataClient(value) {
  return {
    type: GET_DATA_CLIENT,
    value
  };
}
export function getRecommendation(value) {
  return {
    type: GET_RECOMMENDATION,
    value
  };
}
export function getDataRecommendaion(key,value) {
  return {
    type: GET_DATA_RECOMMENDAION,
    key,
    value
  };
}
export function getRecommendaionList(key,value) {
  return {
    type: GET_RECOMMENDATION_LIST,
    key,
    value
  };
}
export function modal(event) {
  return {
    type: MODAL,
    event
  };
}
export function dropdown(event) {
  return {
    type: DROPDOWN,
    event
  };
}
export function resetData() {
  return {
    type: RESET,
  };
}


export function pushData() {
  return {
    type: PUSH_DATA,
  };
}
export function pushDataSuccess(key, value) {
  return {
    type: PUSH_DATA_SUCCESS,
    key,
    value
  };
}

export function getDataElement(data1, data2) {
  return {
    type: GET_DATA_ELEMENT,
    data1,
    data2
  };
}

export function getDataProduct(data1, data2) {
  return {
    type: GET_DATA_PRODUCT,
    data1,
    data2
  };
}

export function deleteRecommendation(key,value) {
  return {
    type: DELETE_RECOMMENDAION,
    key,
    value
  };
}
export function getDiscount(value) {
  return {
    type: GET_DISCOUNT,
    value
  };
}
