const branch = process.env.REACT_APP_BRANCH_NAME || '';

console.log('branch detected', branch);

let config = {};

// Check if branch name matches with the environment
if (branch === 'main') {
 config = require(`./dev.json`);
} else if (branch === 'staging'){
 config = require(`./staging.json`);
}
else if(branch === 'live') {
 config = require(`./live.json`);
}

if(config == {}){
    alert('no config found for this branch');
}


export default config;
