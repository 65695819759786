import { fromJS } from "immutable";
import {
  GET_REGIONS, GET_REGION_ERROR, GET_REGIONS_SUCCESS,
  GET_AREAS, GET_AREA_ERROR, GET_AREA_SUCCESS,
  GET_CATEGORY, GET_CATEGORY_ERROR, GET_CATEGORY_SUCCESS,
  GET_ELEMENT, GET_ELEMENT_ERROR, GET_ELEMENT_SUCCESS,
  GET_IMCOMPATIBLE_PRODUCT, GET_IMCOMPATIBLE_PRODUCT_ERROR, GET_IMCOMPATIBLE_PRODUCT_SUCCESS,
  GET_MIX_TYPE, GET_MIX_TYPE_ERROR, GET_MIX_TYPE_SUCCESS,
  GET_NCR, GET_NCR_ERROR, GET_NCR_SUCCESS,
  GET_PACKAGING, GET_PACKAGING_ERROR, GET_PACKAGING_SUCCESS,
  GET_PRODUCT, GET_PRODUCT_ERROR, GET_PRODUCT_SUCCESS,
  GET_STATUS, GET_STATUS_ERROR, GET_STATUS_SUCCESS,
  GET_LABOUR, GET_LABOUR_ERROR, GET_LABOUR_SUCCESS,
  GET_MATERIAL_COSTS, GET_MATERIAL_COSTS_ERROR, GET_MATERIAL_COSTS_SUCCESS,
  GET_CLIENTS, GET_CLIENTS_ERROR, GET_CLIENTS_SUCCESS,
  GET_RESELLERS, GET_RESELLERS_ERROR, GET_RESELLERS_SUCCESS,
  UPDATE_DATA, UPDATE_DATA_ERROR, UPDATE_DATA_SUCCESS,
  RECOMMENDAION_HEADER, RECOMMENDAION_HEADER_ERROR, RECOMMENDAION_HEADER_SUCCESS,
  RECOMMENDAION_LINE, RECOMMENDAION_LINE_ERROR, RECOMMENDAION_LINE_SUCCESS,
  FETCH_ALL, HIDE_SPINNER, LOG_OUT, GET_DATA, UPDATE_GLOBAL_DATA,
  CONFIG_FILE, CONFIG_FILE_ERROR, CONFIG_FILE_SUCCESS,
  SYNC_DATA, RESUME_APP,
  GET_DISCOUNT
} from "./constants";
import Region from "models/Region";
import Area from "models/Areas";
import Category from "models/Category";
import Element from "models/Element";
import ImcompatibleProducts from "models/IncompatibleProducts";
import MixType from "models/MixType";
import NCRs from "models/NCRs";
import Packaging from "models/Packaging";
import Product from "models/Product";
import Status from "models/Status";
import MaterialCosts from "models/MaterialCosts";
import Labour from "models/Labour";
import ClientsList from "models/ClientsList";
import ResellersList from "models/ResellersList";
import DataGlobalUpdate from "models/DataGlobalUpdate";
import RecommendationHeader from "models/RecommendationHeader";
import RecommendationLine from "models/RecommendationLine";
import ConfigFile from "models/ConfigFile";

let defaultFirstName = '';
if(window.localStorage.getItem('firstName') != null) {
  defaultFirstName = window.localStorage.getItem('firstName');
}
let defaultSyncData = '';
if(window.localStorage.getItem('syncData') != null) {
  defaultSyncData = window.localStorage.getItem('syncData');
}
let defaultRoleAccount = '';
if(window.localStorage.getItem('roleAccount') != null) {
  defaultRoleAccount = window.localStorage.getItem('roleAccount');
}
let defaultListClient = '';
if(window.localStorage.getItem('listClient') != null) {
  defaultListClient = JSON.parse(window.localStorage.getItem('listClient'));
}
let defaultFlagSyncData = false;
if(window.localStorage.getItem('flagSyncData') != null) {
  defaultFlagSyncData = window.localStorage.getItem('flagSyncData');
}
let defaultNutritechMargin = '';
if(window.localStorage.getItem('nutritechMargin') != null) {
  defaultNutritechMargin = window.localStorage.getItem('nutritechMargin');
}
let defaultResellerMargin = '';
if(window.localStorage.getItem('resellerMargin') != null) {
  defaultResellerMargin = window.localStorage.getItem('resellerMargin');
}
let defaultContactPhone = '8088 000 000';
if(window.localStorage.getItem('contactPhone') != null) {
  defaultContactPhone = window.localStorage.getItem('contactPhone');
}

export const initialState = fromJS({
  firstName: defaultFirstName,
  syncData: defaultSyncData,
  spinner: false,
  connection: false,
  roleAccount: defaultRoleAccount,
  modelSyncData: false,
  modelSyncError: false,
  showModalErrorSync: false,
  textSyncError: '',
  listClient: '',
  flagSyncData: defaultFlagSyncData,
  nutritechMargin: defaultNutritechMargin,
  resellerMargin: defaultResellerMargin,
  contactPhone: defaultContactPhone
});

function toKnack(type, res, extension) {

  let result = [];
  if (res && res.length > 0) {
    type === "Region" &&
      res.map(item => {
        result.push(Region.toItem(item));
      });
    type === "Area" &&
      res.map(item => {
        result.push(Area.toItem(item));
      });
    type === "Category" &&
      res.map(item => {
        result.push(Category.toItem(item));
      });
    type === "Element" &&
      res.map(item => {
        result.push(Element.toItem(item));
      });
    type === "imcompatibleProducts" &&
      res.map(item => {
        result.push(ImcompatibleProducts.toItem(item));
      });
    type === "mixType" &&
      res.map(item => {
        result.push(MixType.toItem(item));
      });
    type === "NCRs" &&
      res.map(item => {
        result.push(NCRs.toItem(item));
      });
    type === "Packaging" &&
      res.map(item => {
        result.push(Packaging.toItem(item));
      });
    type === "Product" &&
      res.map(item => {
        result.push(Product.toItem(item));
      });
    type === "Status" &&
      res.map(item => {
        result.push(Status.toItem(item));
      });
    type === "Labour" &&
      res.map(item => {
        result.push(Labour.toItem(item));
      });
    type === "MaterialCosts" &&
      res.map(item => {
        result.push(MaterialCosts.toItem(item));
      });
    type === "ClientsList" &&
      res.map(item => {
        result.push(ClientsList.toItem(item, extension));
      });
    type === "ResellersList" &&
      res.map(item => {
        result.push(ResellersList.toItem(item));
      });
    type === "DataGlobalUpdate" &&
      res.map(item => {
        result.push(DataGlobalUpdate.toItem(item, extension));
      });
    type === "RecommendationHeader" &&
      res.map(item => {
        result.push(RecommendationHeader.toItem(item));
      });
    type === "RecommendationLine" &&
      res.map(item => {
        result.push(RecommendationLine.toItem(item));
      });
    type === "ConfigFile" &&
      res.map(item => {
        result.push(ConfigFile.toItem(item));
      });
  }

  return result;
}
function getData(state = initialState, action) {
  switch (action.type) {
    case GET_REGIONS:
      return state;

    case GET_DATA:
      return state.set(action.key, action.value);

    case GET_REGIONS_SUCCESS: {
      Region.create(toKnack("Region", action.value.data.records));
      return state;
    }
    case GET_REGION_ERROR:
      return state;

    case GET_AREAS:
      return state;

    case GET_AREA_SUCCESS: {
      Area.create(toKnack("Area", action.value.data.records));
      return state;
    }
    case GET_AREA_ERROR:
      return state;

    case GET_CATEGORY:
      return state;

    case GET_CATEGORY_SUCCESS: {
      Category.create(toKnack("Category", action.value.data.records));
      return state;
    }
    case GET_CATEGORY_ERROR:
      return state;

    case GET_ELEMENT:
      return state;

    case GET_ELEMENT_SUCCESS: {
      Element.create(toKnack("Element", action.value.data.records));
      return state;
    }
    case GET_ELEMENT_ERROR:
      return state;

    case GET_IMCOMPATIBLE_PRODUCT:
      return state;

    case GET_IMCOMPATIBLE_PRODUCT_SUCCESS: {
      ImcompatibleProducts.create(toKnack("imcompatibleProducts", action.value.data.records));
      return state;
    }
    case GET_IMCOMPATIBLE_PRODUCT_ERROR:
      return state;

    case GET_MIX_TYPE:
      return state;

    case GET_MIX_TYPE_SUCCESS: {
      MixType.create(toKnack("mixType", action.value.data.records));
      return state;
    }
    case GET_MIX_TYPE_ERROR:
      return state;

    case GET_NCR:
      return state;

    case GET_NCR_SUCCESS: {
      NCRs.create(toKnack("NCRs", action.value.data.records));
      return state;
    }
    case GET_NCR_ERROR:
      return state;

    case GET_PACKAGING:
      return state;

    case GET_PACKAGING_SUCCESS: {
      Packaging.create(toKnack("Packaging", action.value.data.records));
      return state;
    }
    case GET_PACKAGING_ERROR:
      return state;

    case GET_PRODUCT:
      return state;

    case GET_PRODUCT_SUCCESS: {
      Product.create(toKnack("Product", action.value.data.records));
      return state;
    }
    case GET_PRODUCT_ERROR:
      return state;

    case GET_STATUS:
      return state;

    case GET_STATUS_SUCCESS: {
      Status.create(toKnack("Status", action.value.data.records));
      return state;
    }
    case GET_STATUS_ERROR:
      return state;

    case GET_LABOUR:
      return state;

    case GET_LABOUR_SUCCESS: {
      Labour.create(toKnack("Labour", action.value.data.records));
      return state;
    }
    case GET_LABOUR_ERROR:
      return state;

    case GET_MATERIAL_COSTS:
      return state;

    case GET_MATERIAL_COSTS_SUCCESS: {
      MaterialCosts.create(toKnack("MaterialCosts", action.value.data.records));
      return state;
    }
    case GET_MATERIAL_COSTS_ERROR:
      return state;

    case GET_CLIENTS:
      return state;
    case GET_CLIENTS_SUCCESS: {
      if (action.value1.data.records.length !== 0) {
        ClientsList.create(toKnack("ClientsList", action.value1.data.records, action.value2));
      }
      return state;
    }
    case GET_CLIENTS_ERROR:
      return state;

    case GET_RESELLERS:
      return state;

    case GET_RESELLERS_SUCCESS: {
      if (action.value.length != 0 && action.value.data.records.length != 0) {
        ResellersList.create(toKnack("ResellersList", action.value.data.records));
      }
      return state;
    }
    case GET_RESELLERS_ERROR:
      return state;

    case RECOMMENDAION_HEADER:
      return state;

    case RECOMMENDAION_HEADER_SUCCESS: {
      if (action.value.data.records.length !== 0) {
        RecommendationHeader.create(toKnack("RecommendationHeader", action.value.data.records));
      }
      return state;
    }
    case RECOMMENDAION_HEADER_ERROR:
      return state;

    case RECOMMENDAION_LINE:
      return state;

    case RECOMMENDAION_LINE_SUCCESS: {
      if (action.value.data.records.length !== 0) {
        RecommendationLine.create(toKnack("RecommendationLine", action.value.data.records));
      }
      return state;
    }
    case RECOMMENDAION_LINE_ERROR:
      return state;

    case UPDATE_DATA:
      return state;

    case UPDATE_DATA_SUCCESS: {
      DataGlobalUpdate.create(toKnack("DataGlobalUpdate", action.value1.data.records, action.value2));
      return state;
    }
    case UPDATE_DATA_ERROR:
      return state;

    case CONFIG_FILE:
      return state;

    case CONFIG_FILE_SUCCESS: {
      ConfigFile.create(toKnack("ConfigFile", action.value.data.records));
      return state;
    }
    case CONFIG_FILE_ERROR:
      return state;

    case FETCH_ALL:
      return state;

    case HIDE_SPINNER:
      return state.set('spinner', action.value);

    case UPDATE_GLOBAL_DATA:
      return state.set(action.key, action.value);

    case LOG_OUT:
      return state;

    case SYNC_DATA:
      return state;

    case RESUME_APP:
      return state;
    case GET_DISCOUNT: {
      return state;
    }
    default:
      return state;
  }
}
export default getData;
