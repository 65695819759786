
export const GET_DATA = "app/GET_DATA";

export const FETCH_ALL = "app/FETCH_ALL";
export const HIDE_SPINNER = "app/HIDE_SPINNER";

export const GET_AREA_ERROR = "app/GET_AREA_ERROR";
export const GET_AREA_SUCCESS = "app/GET_AREA_SUCCESS";
export const GET_AREAS = "app/GET_AREAS";

export const GET_CATEGORY_ERROR = "app/GET_CATEGORY_ERROR";
export const GET_CATEGORY_SUCCESS = "app/GET_CATEGORY_SUCCESS";
export const GET_CATEGORY = "app/GET_CATEGORY";

export const GET_ELEMENT_ERROR = "app/GET_ELEMENT_ERROR";
export const GET_ELEMENT_SUCCESS = "app/GET_ELEMENT_SUCCESS";
export const GET_ELEMENT = "app/GET_ELEMENT";

export const GET_IMCOMPATIBLE_PRODUCT_ERROR = "app/GET_IMCOMPATIBLE_PRODUCT_ERROR";
export const GET_IMCOMPATIBLE_PRODUCT_SUCCESS = "app/GET_IMCOMPATIBLE_PRODUCT_SUCCESS";
export const GET_IMCOMPATIBLE_PRODUCT = "app/GET_IMCOMPATIBLE_PRODUCT";

export const GET_MIX_TYPE_ERROR = "app/GET_MIX_TYPE_ERROR";
export const GET_MIX_TYPE_SUCCESS = "app/GET_MIX_TYPE_SUCCESS";
export const GET_MIX_TYPE = "app/GET_MIX_TYPE";

export const GET_NCR_ERROR = "app/GET_NCR_ERROR";
export const GET_NCR_SUCCESS = "app/GET_NCR_SUCCESS";
export const GET_NCR = "app/GET_NCR";

export const GET_PACKAGING_ERROR = "app/GET_PACKAGING_ERROR";
export const GET_PACKAGING_SUCCESS = "app/GET_PACKAGING_SUCCESS";
export const GET_PACKAGING = "app/GET_PACKAGING";

export const GET_PRODUCT_ERROR = "app/GET_PRODUCT_ERROR";
export const GET_PRODUCT_SUCCESS = "app/GET_PRODUCT_SUCCESS";
export const GET_PRODUCT = "app/GET_PRODUCT";

export const GET_REGION_ERROR = "app/GET_REGION_ERROR";
export const GET_REGIONS_SUCCESS = "app/GET_REGIONS_SUCCESS";
export const GET_REGIONS = "app/GET_REGIONS";

export const GET_STATUS_ERROR = "app/GET_STATUS_ERROR";
export const GET_STATUS_SUCCESS = "app/GET_STATUS_SUCCESS";
export const GET_STATUS = "app/GET_STATUS";

export const GET_LABOUR_ERROR = "app/GET_LABOUR_ERROR";
export const GET_LABOUR_SUCCESS = "app/GET_LABOUR_SUCCESS";
export const GET_LABOUR = "app/GET_LABOUR";

export const GET_MATERIAL_COSTS_ERROR = "app/GET_MATERIAL_COSTS_ERROR";
export const GET_MATERIAL_COSTS_SUCCESS = "app/GET_MATERIAL_COSTS_SUCCESS";
export const GET_MATERIAL_COSTS = "app/GET_MATERIAL_COSTS";

export const GET_CLIENTS_ERROR = "app/GET_CLIENTS_ERROR";
export const GET_CLIENTS_SUCCESS = "app/GET_CLIENTS_SUCCESS";
export const GET_CLIENTS = "app/GET_CLIENTS";

export const GET_RESELLERS_ERROR = "app/GET_RESELLERS_ERROR";
export const GET_RESELLERS_SUCCESS = "app/GET_RESELLERS_SUCCESS";
export const GET_RESELLERS = "app/GET_RESELLERS";

export const RECOMMENDAION_HEADER_ERROR = "app/RECOMMENDAION_HEADER_ERROR";
export const RECOMMENDAION_HEADER_SUCCESS = "app/RECOMMENDAION_HEADER_SUCCESS";
export const RECOMMENDAION_HEADER = "app/RECOMMENDAION_HEADER";

export const RECOMMENDAION_LINE_ERROR = "app/RECOMMENDAION_LINE_ERROR";
export const RECOMMENDAION_LINE_SUCCESS = "app/RECOMMENDAION_LINE_SUCCESS";
export const RECOMMENDAION_LINE = "app/RECOMMENDAION_LINE";

export const UPDATE_DATA_ERROR = "app/UPDATE_DATA_ERROR";
export const UPDATE_DATA_SUCCESS = "app/UPDATE_DATA_SUCCESS";
export const UPDATE_DATA = "app/UPDATE_DATA";

export const CONFIG_FILE_ERROR = "app/CONFIG_FILE_ERROR";
export const CONFIG_FILE_SUCCESS = "app/CONFIG_FILE_SUCCESS";
export const CONFIG_FILE = "app/CONFIG_FILE";

export const SYNC_DATA = "app/SYNC_DATA";
export const RESUME_APP = "app/RESUME_APP";

export const LOG_OUT = "app/LOG_OUT";
export const UPDATE_GLOBAL_DATA = "app/UPDATE_GLOBAL_DATA";

export const CHECK_UNSYNC_DATA = "app/CHECK_UNSYNC_DATA";
export const GET_TERM_CONDITIONS = "app/GET_TERM_CONDITIONS";
export const SAVE_TERM_CONDITIONS = "app/SAVE_TERM_CONDITIONS";

export const SEARCH_PRODUCT = "app/SEARCH_PRODUCT";

export const GET_DISCOUNT = "app/GET_DISCOUNT";
