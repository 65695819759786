/**
 *
 * NavigationBar
 *
 */

import PropTypes from "prop-types";
import React from "react";
import "./style.scss";

// import styled from 'styled-components';

function NavigationBar(props) {
    return (
        <div className="navigation-bar row align-items-center w-100 m-0">
            {props.iconLeft &&
                <div className="col-3 left pr-0 pl-0">
                        <button
                            type="button"
                            className="btn btn-left w-100 h-100"
                            onClick={props.onLeftClick}
                        >
                            {props.iconLeft && <i className={props.iconLeft}/>}
                            {props.textIcon && <span className="text-icon">{props.textIcon}</span>}
                        </button>
                </div>
            }
            {props.textCenter &&
                <div className={props.iconRight ? "col-8 center" : "col-9 center"}>
                        <div className="center-text">{props.textCenter}</div>
                </div>
            }
            {props.iconRight &&
                <div className="col-1 right pl-0">
                        <button
                            type="button nav-btn"
                            className="btn w-100 h-100"
                            onClick={props.onRightClick}
                        >
                            {props.iconRight && <i className={props.iconRight}/>}
                        </button>
                </div>
            }
            <div className="header col-12">
                {props.textTopInfo &&
                    <div className="info">{props.textTopInfo}<div className="detail">{props.textTopDetail}</div></div>
                }
                {props.textTitle &&
                    <div className="title">{props.textTitle}</div>
                }
                {props.textBottomInfo &&
                    <div className="info">{props.textBottomInfo}<span className="detail">{props.textBottomDetail}</span></div>
                }
                {props.textBottomInfoExtra &&
                    <div className="info">{props.textBottomInfoExtra}<span className="detail">{props.textBottomDetailExtra}</span></div>
                }
            </div>
        </div>
    );
}

NavigationBar.propTypes = {
    textCenter: PropTypes.string,
    textTitle: PropTypes.string,
    textIcon: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    textTopInfo: PropTypes.string,
    textTopDetail: PropTypes.string,
    textBottomInfo: PropTypes.string,
    textBottomDetail: PropTypes.string,
    onLeftClick: PropTypes.func,
    onRightClick: PropTypes.func,
};

export default NavigationBar;
