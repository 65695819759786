import {
  CHECK_UNSYNC_DATA,
  CONFIG_FILE,
  CONFIG_FILE_ERROR,
  CONFIG_FILE_SUCCESS,
  FETCH_ALL,
  GET_AREA_ERROR,
  GET_AREA_SUCCESS,
  GET_AREAS,
  GET_CATEGORY,
  GET_CATEGORY_ERROR,
  GET_CATEGORY_SUCCESS,
  GET_CLIENTS,
  GET_CLIENTS_ERROR,
  GET_CLIENTS_SUCCESS,
  GET_DATA,
  GET_ELEMENT,
  GET_ELEMENT_ERROR,
  GET_ELEMENT_SUCCESS,
  GET_IMCOMPATIBLE_PRODUCT,
  GET_IMCOMPATIBLE_PRODUCT_ERROR,
  GET_IMCOMPATIBLE_PRODUCT_SUCCESS,
  GET_LABOUR,
  GET_LABOUR_ERROR,
  GET_LABOUR_SUCCESS,
  GET_MATERIAL_COSTS,
  GET_MATERIAL_COSTS_ERROR,
  GET_MATERIAL_COSTS_SUCCESS,
  GET_MIX_TYPE,
  GET_MIX_TYPE_ERROR,
  GET_MIX_TYPE_SUCCESS,
  GET_NCR,
  GET_NCR_ERROR,
  GET_NCR_SUCCESS,
  GET_PACKAGING,
  GET_PACKAGING_ERROR,
  GET_PACKAGING_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_ERROR,
  GET_PRODUCT_SUCCESS,
  GET_REGION_ERROR,
  GET_REGIONS,
  GET_REGIONS_SUCCESS,
  GET_RESELLERS,
  GET_RESELLERS_ERROR,
  GET_RESELLERS_SUCCESS,
  GET_STATUS,
  GET_STATUS_ERROR,
  GET_STATUS_SUCCESS,
  GET_TERM_CONDITIONS,
  HIDE_SPINNER,
  LOG_OUT,
  RECOMMENDAION_HEADER,
  RECOMMENDAION_HEADER_ERROR,
  RECOMMENDAION_HEADER_SUCCESS,
  RECOMMENDAION_LINE,
  RECOMMENDAION_LINE_ERROR,
  RECOMMENDAION_LINE_SUCCESS,
  RESUME_APP,
  SAVE_TERM_CONDITIONS, SEARCH_PRODUCT,
  SYNC_DATA,
  UPDATE_DATA,
  UPDATE_DATA_ERROR,
  UPDATE_DATA_SUCCESS,
  UPDATE_GLOBAL_DATA,
  GET_DISCOUNT
} from "./constants";

export function getData(key, value) {

  let keysToPersist = ['firstName','syncData','roleAccount','listClient','flagSyncData','nutritechMargin','resellerMargin','contactPhone'];
  
  if(keysToPersist.indexOf(key) > -1) {
    if(key == 'listClient') {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
    else
    {
      window.localStorage.setItem(key, value);
    }
  }

  return {
    type: GET_DATA,
    key,
    value
  };
}
export function hideSpinner(value) {
  return {
    type: HIDE_SPINNER,
    value
  };
}
export function fetchData() {
  return {
    type: FETCH_ALL
  };
}

export function getAreas() {
  return {
    type: GET_AREAS
  };
}
export function getAreaSuccess(value) {
  return {
    type: GET_AREA_SUCCESS,
    value
  };
}
export function getAreaError() {
  return {
    type: GET_AREA_ERROR
  };
}

export function getCategory() {
  return {
    type: GET_CATEGORY
  };
}
export function getCategorySuccess(value) {
  return {
    type: GET_CATEGORY_SUCCESS,
    value
  };
}
export function getCategoryError() {
  return {
    type: GET_CATEGORY_ERROR
  };
}

export function getElement() {
  return {
    type: GET_ELEMENT
  };
}
export function getElementSuccess(value) {
  return {
    type: GET_ELEMENT_SUCCESS,
    value
  };
}
export function getElementError() {
  return {
    type: GET_ELEMENT_ERROR
  };
}

export function getImcompatibleProduct() {
  return {
    type: GET_IMCOMPATIBLE_PRODUCT
  };
}
export function getImcompatibleProductSuccess(value) {
  return {
    type: GET_IMCOMPATIBLE_PRODUCT_SUCCESS,
    value
  };
}
export function getImcompatibleProductError() {
  return {
    type: GET_IMCOMPATIBLE_PRODUCT_ERROR
  };
}

export function getMixType() {
  return {
    type: GET_MIX_TYPE
  };
}
export function getMixTypeSuccess(value) {
  return {
    type: GET_MIX_TYPE_SUCCESS,
    value
  };
}
export function getMixTypeError() {
  return {
    type: GET_MIX_TYPE_ERROR
  };
}

export function getNCRs() {
  return {
    type: GET_NCR
  };
}
export function getNCRsSuccess(value) {
  return {
    type: GET_NCR_SUCCESS,
    value
  };
}
export function getNCRsError() {
  return {
    type: GET_NCR_ERROR
  };
}

export function getPackaging() {
  return {
    type: GET_PACKAGING
  };
}
export function getPackagingSuccess(value) {
  return {
    type: GET_PACKAGING_SUCCESS,
    value
  };
}
export function getPackagingError() {
  return {
    type: GET_PACKAGING_ERROR
  };
}

export function getProduct() {
  return {
    type: GET_PRODUCT
  };
}
export function getProductSuccess(value) {
  return {
    type: GET_PRODUCT_SUCCESS,
    value
  };
}
export function getProductError() {
  return {
    type: GET_PRODUCT_ERROR
  };
}

export function getRegion() {
  return {
    type: GET_REGIONS
  };
}
export function getRegionSuccess(value) {
  return {
    type: GET_REGIONS_SUCCESS,
    value
  };
}
export function getRegionError() {
  return {
    type: GET_REGION_ERROR
  };
}

export function getStatus() {
  return {
    type: GET_STATUS
  };
}
export function getStatusSuccess(value) {
  return {
    type: GET_STATUS_SUCCESS,
    value
  };
}
export function getStatusError() {
  return {
    type: GET_STATUS_ERROR
  };
}

export function getLabour() {
  return {
    type: GET_LABOUR
  };
}
export function getLabourSuccess(value) {
  return {
    type: GET_LABOUR_SUCCESS,
    value
  };
}
export function getLabourError() {
  return {
    type: GET_LABOUR_ERROR
  };
}

export function getMaterialCosts() {
  return {
    type: GET_MATERIAL_COSTS
  };
}
export function getMaterialCostsSuccess(value) {
  return {
    type: GET_MATERIAL_COSTS_SUCCESS,
    value
  };
}
export function getMaterialCostsError() {
  return {
    type: GET_MATERIAL_COSTS_ERROR
  };
}

export function getClients() {
  return {
    type: GET_CLIENTS
  };
}
export function getClientsSuccess(value1, value2) {
  return {
    type: GET_CLIENTS_SUCCESS,
    value1,
    value2
  };
}
export function getClientsError() {
  return {
    type: GET_CLIENTS_ERROR
  };
}

export function getResellers() {
  return {
    type: GET_RESELLERS
  };
}
export function getResellersSuccess(value) {
  return {
    type: GET_RESELLERS_SUCCESS,
    value
  };
}
export function getResellersError() {
  return {
    type: GET_RESELLERS_ERROR
  };
}

export function updateData() {
  return {
    type: UPDATE_DATA
  };
}
export function updateDataSuccess(value1, value2) {
  return {
    type: UPDATE_DATA_SUCCESS,
    value1,
    value2
  };
}
export function updateDataError() {
  return {
    type: UPDATE_DATA_ERROR
  };
}

export function logOut() {
  return {
    type: LOG_OUT
  };
}

export function recommendationHeader() {
  return {
    type: RECOMMENDAION_HEADER
  };
}
export function recommendationHeaderSuccess(value) {
  return {
    type: RECOMMENDAION_HEADER_SUCCESS,
    value
  };
}
export function recommendationHeaderError() {
  return {
    type: RECOMMENDAION_HEADER_ERROR
  };
}

export function recommendationLine() {
  return {
    type: RECOMMENDAION_LINE
  };
}
export function recommendationLineSuccess(value) {
  return {
    type: RECOMMENDAION_LINE_SUCCESS,
    value
  };
}
export function recommendationLineError() {
  return {
    type: RECOMMENDAION_LINE_ERROR
  };
}

export function configFile() {
  return {
    type: CONFIG_FILE
  };
}

export function configFileSuccess(value) {
  return {
    type: CONFIG_FILE_SUCCESS,
    value
  };
}

export function configFileError() {
  return {
    type: CONFIG_FILE_ERROR
  };
}

export function updateGlobalData(key, value) {
  return {
    type: UPDATE_GLOBAL_DATA,
    key,
    value
  };
}
export function syncData(pathToGo, forceRefresh, history) {

  return {
    type: SYNC_DATA,
    pathToGo,
    forceRefresh,
    history
  };
}
/*export function resumeApp(pathToGo) {
  return {
    type: RESUME_APP,
    pathToGo
  };
}*/

export function checkUnsyncData(resolve, reject) {
  return {
    type: CHECK_UNSYNC_DATA,
    resolve,
    reject
  };
}

export function getTermConditions() {
  return {
    type: GET_TERM_CONDITIONS
  };
}
export function saveTermConditions(value) {
  return {
    type: SAVE_TERM_CONDITIONS,
    value
  };
}
export function searchProductAction(params, resolve, reject) {

  return {
    type: SEARCH_PRODUCT,
    params,
    resolve, reject
  };
}

export function getDiscount(value) {
  return {
    type: GET_DISCOUNT,
    value
  };
}
