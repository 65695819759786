//  id Liquid = 5c1a7c1017476f49f4e76f6a
import config from "configApp";
import { EVENT } from "jsstore";
export function elementalDose(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {

    let res = 0;
    inputDose = parseFloat(inputDose);

    if (product.elementalCalculation === 1) {
        res = inputDose;
    } else if (product.elementalCalculation === 2) {
        res = (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 0.8) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType)
    } else if (product.elementalCalculation === 3 && product.idProduct != config.knack.constants.productDCP18Id) {
        data.map(item => {
          if (item.element.idProduct == config.knack.constants.productDCP18Id) {
            res = dosePerCow(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType) * (product.ingredient.replace(',', '') / 100) * 1000
          }
        })
    } else if (product.elementalCalculation === 4) {
        res = (((125 * batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType)) / 1000) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType)) / 5;
    } else if (product.elementalCalculation === 5) {
        res = ((batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 1000 * 0.02) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType)) / 2
    } else if (product.elementalCalculation === 6) {
        res = dosePerCow(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * (product.ingredient.replace(',','') / 100) * 1000;
    } else if (product.elementalCalculation === 7) {
        res = (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 312.5 ) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    } else if (product.elementalCalculation === 8) {
        res = (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 8.75) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    } else if (product.elementalCalculation === 9) {
        res = (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 8.9) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    } else if (product.elementalCalculation === 10) {
        res = ((batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / 1000) * 300) / dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    }
    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}

export function wsTotal1(data, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    data.map((item) => {
        if (item.element.elementalCalculation === 3) {
            res = res + elementalDose(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType)
        }
    })
    return res;
}

export function dosePerCow(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    if (product.dosePerCow === 1) {
        res = elementalDose(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / (product.ingredient.replace(',','') / 100) / 1000;
    } else if (product.dosePerCow === 2) {
        res = elementalDose(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / (product.ingredient.replace(',','') / 100);
    } else if (product.dosePerCow === 3) {
        res = elementalDose(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    } else if (product.dosePerCow === 4 || product.dosePerCow === 5) {
        res = Math.ceil(sumDosePerCow1(data, numberOfCow, dayRequired, bagSize, carrier, mixType) * (1 + (carrier / 100))) * (carrier / 100);
    } else if (product.dosePerCow === 6) {
        res = (elementalDose(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) - wsTotal1(data, numberOfCow, dayRequired, bagSize, carrier, mixType)) / (product.ingredient.replace(',','') / 100) / 1000
    }
    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}

export function gramsPesBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    if (product.gramsPesBatch === 1) {
        res = dosePerCow(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    } else if (product.gramsPesBatch === 2) {
        res = (dosePerCow(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType)) / 1000;
    } else if (product.gramsPesBatch === 3) {
        res = (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 1000) - sumGramsPesBatch(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    }
    if (!Number.isFinite(res)) {
        res = 0
    }

    return res;
}


export function lineCostPerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    if (product.costPerBatch === 1) {
        res = (gramsPesBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * product.costPer) / 1000;
    } else if (product.costPerBatch === 2) {
        res = gramsPesBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * product.costPer;
    }

    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}

export function doseSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = Math.ceil( (sumDosePerCow1(data, numberOfCow, dayRequired, bagSize, carrier, mixType) * (1 + (carrier / 100))) );;
    /*if (mixType.toLowerCase() === "liquid") {
        res = Math.ceil(( sumDosePerCow1(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * (1 + (carrier / 100)) / 1000));
    } else {
        res = Math.ceil( (sumDosePerCow1(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * (1 + (carrier / 100))) );
    }*/

    if (!Number.isFinite(res)) {
        res = 0
    }

    return res;
}

export function totalRequired(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    if (mixType.toLowerCase() === "liquid") {
        res = (numberOfCow * dayRequired * doseSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType)) / 1000;
    } else {
        res = (numberOfCow * dayRequired * doseSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType));
    }
    return res;
}


export function batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    if (mixType.toLowerCase() === "liquid") {
        res = Math.ceil(totalRequired(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / bagSize) * bagSize;
    } else {
        res = Math.ceil((totalRequired(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / 1000) / bagSize) * bagSize;
    }

    if (!Number.isFinite(res)) {
        res = 0
    }

    return res;
}

export function dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / doseSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * 1000;

    if (!Number.isFinite(res)) {
        res = 0
    }

    return res;
}


export function headerCostPerBatch(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    data.map((item) => {
        res = res + lineCostPerBatch(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType)
    })
    return res;
}

export function rawMaterialCost(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    res = headerCostPerBatch(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / (batchSize(data[0].element, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / bagSize)
    return res
}

export function freightCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType, freightPerUnit) {
    let res = 0;
    res = (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / 1000) * freightPerUnit
    return res
}

export function palletCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType, palletEach) {
    let palletDivisor = 0;
    if (mixType.toLowerCase() === "liquid") {
        palletDivisor = 800
    } else {
        palletDivisor = 1000
    }
    let res = 0;
    res = Math.ceil(batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / palletDivisor) * palletEach  / (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / bagSize)

    return res
}

export function labourCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType, labourHours, labourPerHour) {
    let res = 0;
    let _batchSize = batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);

    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('labourHours in labour cost', labourHours) ;
        console.log('labourPerHour in labour cost', labourPerHour) ;
        console.log('bagSize in labour cost', bagSize) ;
        console.log('batch size in labour cost', _batchSize);
    }
    res = labourHours * labourPerHour / (_batchSize / bagSize);

    console.log('labour cost result', res);
    return res
}

export function totalMaterialCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit) {
    let res = 0;

    deliveryCost = parseFloat(deliveryCost);
    packageCost = parseFloat(packageCost);
    labelCost = parseFloat(labelCost);

    let _rawMaterialCost = rawMaterialCost(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    let _labourCost =labourCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType, labourHours, labourPerHour);
    let _palletCost = palletCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType, palletEach);
    let _batchSize = batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);
    let _freightCost = freightCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType, freightPerUnit);

    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('_rawMaterial in totalMaterialCost', _rawMaterialCost) ;
        console.log('_labourCost in totalMaterialCost', _labourCost) ;
        console.log('_palletCost in totalMaterialCost', _palletCost) ;
        console.log('_batchSize in totalMaterialCost', _batchSize) ;
        console.log('_freightCost in totalMaterialCost', _freightCost) ;
    }

    res = (
        (   _rawMaterialCost
            + packageCost
            + labelCost
            + _labourCost
            + _palletCost
        ) * (_batchSize / bagSize)
        + _freightCost
        + deliveryCost
    )

    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('res in totalMaterialCost', res) ;
    }
    return res
}

export function costWithNutritechMargin(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit, pricing, nutritechMargin, resellerMargin) {
    let res = 0;
    let total = totalMaterialCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit)

    res =  total / ( (100 - nutritechMargin)/ 100)
    res = res / (( 100 - resellerMargin) / 100)

    if (!Number.isFinite(res)) {
        res = 0
    }

    return res
}

export function sellingCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit, pricing, nutritechMargin, resellerMargin ) {
    let res = 0;
    let total = totalMaterialCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit)
    
    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('totalMaterialCost in sellingCost', total)
    }
    
    res =  total / ( (100 - nutritechMargin)/ 100)
    res = res / (( 100 - resellerMargin) / 100)
    if(pricing === ''){
        pricing = 100
    }
    res = res + res*(pricing /100)
    if (!Number.isFinite(res)) {
        res = 0
    }

    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('sellingCost res', res)
    }

    return res
}

export function costPerDose(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit, pricing, nutritechMargin, resellerMargin ) {
    
    deliveryCost = parseFloat(deliveryCost);
    packageCost = parseFloat(packageCost);
    labelCost = parseFloat(labelCost);

    let _sellingCost = sellingCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit, pricing, nutritechMargin, resellerMargin);
    let _dosePerBatch = dosePerBatch(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType);

    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('sellingCost', _sellingCost)
        console.log('dosePerBatch', _dosePerBatch)
    }
    
    let costPerDose = _sellingCost / _dosePerBatch;

    if (!Number.isFinite(costPerDose)) {
        costPerDose = 0
    }
    
    if( process.env.REACT_APP_VERBOSE_LOGGING == 'true') {
        console.log('costPerDose', costPerDose);
    }
    
    return costPerDose;
}

export function costPerUnit(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit, pricing, nutritechMargin, resellerMargin ) {
    
    deliveryCost = parseFloat(deliveryCost);
    packageCost = parseFloat(packageCost);
    labelCost = parseFloat(labelCost);
    
    let costPerDose = sellingCost(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType,palletEach, packageCost, labelCost, deliveryCost,labourHours, labourPerHour,freightPerUnit, pricing, nutritechMargin, resellerMargin ) / (batchSize(product, data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) / bagSize);

    if (!Number.isFinite(costPerDose)) {
        costPerDose = 0
    }

    return costPerDose;
}

export function sumDosePerCow1(data, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    data.map((item) => {
        if (item.element.autoAdd === 'No' && item.element.elementalCalculation != 10) {
            res = res + dosePerCow(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType)
        }
    })

    return res;
}

export function sumGramsPesBatch(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    data.map((item) => {
        if (item.element.autoAdd === 'No' && item.element.elementalCalculation != 10) {
            res = res + gramsPesBatch(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType)
        }
    })

    return res;
}

export function sumDosePerCow2(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    data.map((item) => {
        if (item.element.autoAdd === 'No' && item.element.elementalCalculation != 10) {
            res = res + dosePerCow(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType)
        }
    })

    return res;
}
export function sumElementDose(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) {
    let res = 0;
    data.map((item) => {
        if (item.element.elementalCalculation != 10 && item.element.autoAdd === 'No') {
            res = res + elementalDose(item.element, data, item.dose, numberOfCow, dayRequired, bagSize, carrier, mixType)
        }
    })

    return res;
}

export function totalPrice(data, inputDose, numberOfCow, dayRequired, pricing, bagSize, carrier, mixType) {
    let res = 0;
    res = headerCostPerBatch(data, inputDose, numberOfCow, dayRequired, bagSize, carrier, mixType) * (1 - (pricing / 100))
    return res;
}

export function getLineElementDose(line, headerBatchSize, headerDosePerBatch, dcp18GramPerBatch) {
    switch (line.element.elementalCalculation) {
      case 1:
          return line.dose;
      case 2:
          return headerBatchSize / headerDosePerBatch;
      case 3:
          return (dcp18GramPerBatch * (line.element.ingredient.replace(',','')) * 1000) / headerDosePerBatch;
      case 4:
          return (((125 * headerBatchSize) / 1000) / headerDosePerBatch) / 1000;
      case 5:
          return ((headerBatchSize * 20) / headerDosePerBatch) / 2;
      case 6:
          return line.dosePerCow * (line.element.ingredient.replace(',','')) * 1000;
      case 7:
          return (headerBatchSize * 312.5) / headerDosePerBatch;
      case 8:
          return (headerBatchSize * 8.75) / headerDosePerBatch;
      case 9:
          return (headerBatchSize * 8.9) / headerDosePerBatch;
      case 10:
          return (((headerBatchSize / 1000) * 300) / headerDosePerBatch);
      default:
          return 0;
    }
}

export function getSumElementDose(recommendationLines, isIncludedAutoAdd, isIncludeAllElementCalculation) {
    let res = 0;
    recommendationLines.forEach((o) => {
        res += ((isIncludedAutoAdd ? true : o.element.autoAdd == 'No') && (isIncludeAllElementCalculation ? true : o.element.elementalCalculation != 10)) ? parseFloat(o.dose) : 0;
    });
    return res;
}

export function getSumDosePerCow(recommendationLines, isIncludedAutoAdd, isIncludeAllElementCalculation) {
    let res = 0;
    recommendationLines.forEach((o) => {
        res += ((isIncludedAutoAdd ? true : o.element.autoAdd == 'No') && (isIncludeAllElementCalculation ? true : o.element.elementalCalculation != 10)) ? parseFloat(o.dosePerCow) : 0;
    });
    return res;
}

export function getSumCostPerBatch(recommendationLines, isIncludedAutoAdd, isIncludeAllElementCalculation) {
    let res = 0;
    recommendationLines.forEach((o) => {
        res += ((isIncludedAutoAdd ? true : o.element.autoAdd == 'No') && (isIncludeAllElementCalculation ? true : o.element.elementalCalculation != 10)) ? parseFloat(o.costPerBatch) : 0;
    });
    return res;
}

export function getLineDosePerCow(line, sumElementDose, carrier) {
  let res = 0;
  switch (line.element.dosePerCow) {
    case 1:
        res = line.dose / (line.element.ingredient.replace(',','') / 100) / 1000;
        break;
    case 2:
        res = line.dose / (line.element.ingredient.replace(',','') / 100);
        break;
    case 3:
        res = line.dose;
        break;
    case 4:
    case 5:
        res = Math.ceil(sumElementDose * (1 + (carrier / 100))) * (carrier / 100);
        break;
    default:
        break;
  }
  if (!Number.isFinite(res)) {
    res = 0
  }
  return res;
}

export function getLineGramsPerBatch(line, batchSize, headerDosePerBatch, sumDosePerCow) {
  let res = 0;
  switch (line.element.gramsPesBatch) {
    case 1:
      res = line.dosePerCow * headerDosePerBatch;
      break;
    case 2:
      res = (line.dosePerCow * headerDosePerBatch) / 1000;
      break;
    case 3:
      res = (batchSize * 1000) - sumDosePerCow;
      break;
    default:
      break;
  }
  if (!Number.isFinite(res)) {
    res = 0
  }
  return res;
}

export function getLineCostPerBatch(line) {
  let res = 0;
  switch (line.element.costPerBatch) {
    case 1:
      res = (line.gramsPerBatch * line.element.costPer) / 1000;
      break;
    case 2:
      res = line.gramsPerBatch * line.element.costPer;
      break;
    default:
      break;
  }
  if (!Number.isFinite(res)) {
    res = 0
  }
  return res;
}

export function getHeaderDoseSize(sumDosePerCow, carrier, mixType) {
    let res = 0;
    if (mixType.toLowerCase() == 'liquid') {
        res = Math.ceil((sumDosePerCow * (1 + (carrier / 100))) / 1000);
    } else {
        res = Math.ceil(sumDosePerCow * (1 + (carrier / 100)));
    }
    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}

export function getHeaderTotalRequired(numberOfCow, dayRequired, headerDoseSize, mixType) {
    let res = 0;
    if (mixType.toLowerCase() == 'liquid') {
        res = (numberOfCow * dayRequired * headerDoseSize) / 1000;
    } else {
        res = numberOfCow * dayRequired * headerDoseSize;
    }
    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}

export function getHeaderBatchSize(headerTotalRequired, bagSize, mixType) {
    let res = 0;
    if (mixType.toLowerCase() == 'liquid') {
        res = Math.ceil(headerTotalRequired / bagSize) * bagSize;
    } else {
        res = Math.ceil((headerTotalRequired / 1000) / bagSize) * bagSize;
    }
    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}

export function getHeaderDosesPerBatch(headerBatchSize, headerDoseSize) {
    let res = headerBatchSize * headerDoseSize;
    if (!Number.isFinite(res)) {
        res = 0
    }
    return res;
}
