/*
 *
 * ClientListPage actions
 *
 */

import { DEFAULT_ACTION, SEARCH_CLIENTS, PUSH_DATA, PUSH_DATA_SUCCESS, RESET } from "./constants";

export function defaultAction() {
  return {
    type: DEFAULT_ACTION
  };
}
export function searchClients(key, value) {
  return {
    type: SEARCH_CLIENTS,
    key,
    value
  };
}
export function pushDataClient() {
  return {
    type: PUSH_DATA,
  };
}
export function pushDataSuccess(key,value) {
  return {
    type: PUSH_DATA_SUCCESS,
    key,
    value
  };
}
export function resetDataClient() {
  return {
    type: RESET,
  };
}