/**
 *
 * WarningModal
 *
 */

import React from 'react';
import Modal from 'react-bootstrap4-modal';
import PropTypes from 'prop-types';
import './style.scss';
// import styled from 'styled-components';

class  WarningModal extends React.Component{
  constructor(props) {
    super(props);
  };

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps.visible){
      // Set fixed position for body style, this fixed user can scroll when modal is shown up
      if (document.body.style.position !== 'fixed') {
        document.body.style.top = -window.scrollY + 'px';
        document.body.style.position = 'fixed';
      }
    }
  }

  restoreBodyStyle = () => {
    // Restore body style
    document.body.style.position = '';
    window.scrollTo(0, -parseInt(document.body.style.top, 10));
    document.body.style.top = '';
  };

  render(){
    return (
      <Modal
        visible={this.props.visible}
        className={`warning-modal`}
        dialogClassName={`modal-dialog-centered `}
      >
        <div className="modal-header">{this.props.title}</div>
        <div className="modal-body">{this.props.content}</div>
        {this.props.inputId &&
        <div className="input-modal">
          <input className="text"
                 autoComplete="off"
                 disabled={this.props.onDisabled}
                 type="text" id={this.props.inputId}
                //  pattern="[0-9]*"
                 value={this.props.inputValue}
                 onChange={this.props.inputAction}/>
          <div className={'percent-text'}>%</div>
        </div>
        }
        <div className="error">{this.props.errorText}</div>
        <div className="modal-footer">
          {this.props.cancelText &&
          <button type="button" className="btn btn-cancel" onClick={()=>{
            // Restore body style
            this.restoreBodyStyle();
            this.props.onCancelClick();
          }}>
            {this.props.cancelText}
          </button>
          }
          <button type="button" className="btn btn-ok" onClick={() => {
            // Restore body style
            this.restoreBodyStyle();
            this.props.onOkClick();
          }}>
            {this.props.okText}
          </button>
        </div>
      </Modal>
    );
  }

}

WarningModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  onCancelClick: PropTypes.func,
  onOkClick: PropTypes.func,
  cancelText: PropTypes.string,
  okText: PropTypes.string,
  input: PropTypes.string,
  inputAction: PropTypes.func,
};

export default WarningModal;
