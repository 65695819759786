export const name = 'IncompatibleProducts';
export const fields = {
  id: 'id',
  idProduct: 'idProduct',
  idProduct1: 'id_product_1',
  product1: 'product_1',
  idProduct2: 'id_product_2',
  product2: 'product_2',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idProduct, type: 'text' },
    { name: fields.idProduct1, type: 'text' },
    { name: fields.product1, type: 'text' },
    { name: fields.idProduct2, type: 'text' },
    { name: fields.product2, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
