import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';

export default class ResellersList {
  
  static async getAll(value) {
    let result = await dba.execute(`SELECT * FROM ${name} GROUP BY ${fields.idManageReseller}`);
    return dba.getAll(result);
  }
  
  static async databaseExit(value) {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name} WHERE ${fields.manageUser}='${value}'`);
  }

  static async deleteAll() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async deleteData(value) {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.manageUser} ='${value}' `);
  }

  static async findReseller(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idManageReseller}='${value}'`);
    result = dba.getAll(result);
    return result;
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data) {
 
    let str = data['field_206_raw'] ? data['field_206_raw'][0].identifier : "";
    let emailManage = str.split("<")[1].split(">");

    let result = {
      [fields.idManageReseller]:data.id,
      [fields.manageUser]:emailManage[1],
      [fields.name]:data['field_205'],
      [fields.contactName]:data['field_207'],
      [fields.email]:data['field_208_raw'].email,
      [fields.phone]:data['field_209_raw'].number,
      [fields.markupMin]:data['field_210'],
      [fields.markupMax]:data['field_239'],
      [fields.contact]:data['field_212'],
      [fields.clientDisplay]:data['field_218'],
      [fields.resellerMargin]:data['field_315'] ? data['field_315']: 0,
      [fields.nutritechMargin]:data['field_313']? data['field_313']: 0,
      [fields.manageName]:data['field_220'],
      [fields.areaManager]:data['field_206_raw'] ? data['field_206_raw'][0].id : "",
    }

    return result;
  }
}
