export const name = 'ConfigFile';
export const fields = {
  id: 'id',
  idConfigType: 'idConfigType',
  configType: 'configType',
  account: 'account',
  description: 'description',
  key: 'key',
  status: 'status',
  text: 'text',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT'},
    { name: fields.idConfigType, type: 'text' },
    { name: fields.configType, type: 'text' },
    { name: fields.account, type: 'text' },
    { name: fields.description, type: 'text' },
    { name: fields.key, type: 'text' },
    { name: fields.status, type: 'text' },
    { name: fields.text, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
