import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class MaterialCosts {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}` );
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }
  
  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.mixType}=\'${value}\'`);
    result = dba.getAll(result);
    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data) {
 
    let result = {
      [fields.idMaterialCosts]:data.id,
      [fields.mixType]:data['field_296_raw'][0] ? data['field_296_raw'][0].id : '',
      [fields.freightPerUnit]:data['field_297_raw'],
      [fields.labelCost]:data['field_299_raw'],
      [fields.palletCost]:data['field_300_raw'],
      [fields.ruralDeliveryCost]:data['field_306_raw'],
      [fields.costPerHour]:data['field_311_raw'],
    }

    return result;
  }

}
