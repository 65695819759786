import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class Mail {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async create(id) {

  /*  return dba.execute(
      `INSERT INTO ${name} ({${fields.id}:{'${id}'}) upsert`
    );*/

    let data = {
      id: id
    }
    const sql = `INSERT INTO ${name} VALUES='@values'`;
    return dba.executeQry(sql, [data], true);

  }

  static async delete(id) {
    return dba.execute(`DELETE FROM ${name} WHERE ${fields.id}='${id}'`);
  }
}
