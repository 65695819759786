export const name = 'Labour';
export const fields = {
  id: 'id',
  idLabour: 'idLabour',
  mixSize: 'mixSize',
  hours: 'hours',
  mixType: 'mixType',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idLabour, type: 'text' },
    { name: fields.mixSize, type: 'number' },
    { name: fields.hours, type: 'string' }, //changed from number as value coming from knack is rounded to 2dp and is string.
    { name: fields.mixType, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
