import ClientsList from './ClientsList/schema';
import Areas from './Areas/schema';
import Region from './Region/schema';
import Category from './Category/schema';
import NCRs from './NCRs/schema';
import MixType from './MixType/schema';
import Status from './Status/schema';
import Packaging from './Packaging/schema';
import Product from './Product/schema';
import Element from './Element/schema';
import IncompatibleProducts from './IncompatibleProducts/schema';
import Account from './Account/schema';
import DataGlobalUpdate from './DataGlobalUpdate/schema';
import ResellersList from './ResellersList/schema';
import RecommendationLine from './RecommendationLine/schema';
import RecommendationHeader from './RecommendationHeader/schema';
import ConfigFile from './ConfigFile/schema';
import MaterialCosts from './MaterialCosts/schema';
import Labour from './Labour/schema';
import Mail from './Mail/schema';
//import ResellerClient from './ResellerClient/schema';
//import ResellerRecommendationHeader from './ResellerRecommendationHeader/schema';
//import ResellerRecommendationLine from './ResellerRecommendationLine/schema';
export const DB_CONFIG = {
  tables: [
    ClientsList,
    Areas,
    Region,
    Category,
    NCRs,
    MixType,
    Status,
    Packaging,
    Product,
    Element,
    IncompatibleProducts,
    Account,
    DataGlobalUpdate,
    ResellersList,
    RecommendationLine,
    RecommendationHeader,
    ConfigFile,
    MaterialCosts,
    Labour,
    Mail,
    //ResellerClient,
    //ResellerRecommendationHeader,
    //ResellerRecommendationLine
  ],
};
