export const name = 'RecommendationLine';
export const fields = {
  id: 'id',
  idRecommendationLine: 'idRecommendationLine',
  idRecommendation: 'idRecommendation',
  recommedationHeader: 'recommedationHeader',
  product: 'product',
  inputDose: 'inputDose',
  elementalDose: 'elementalDose',
  dosePerCow: 'dosePerCow',
  gramsPerBatch: 'gramsPerBatch',
  costPerBatch: 'costPerBatch',
  sortKey: 'sortKey',
  updateData: 'updateData',
  areaManager: 'areaManager',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'text PRIMARY KEY' },
    { name: fields.idRecommendationLine, type: 'text' },
    { name: fields.idRecommendation, type: 'text' },
    { name: fields.recommedationHeader, type: 'text' }, //number is coming from knack but store as string as new unsycned lines have guid.
    { name: fields.product, type: 'text' },
    { name: fields.inputDose, type: 'text' },
    { name: fields.elementalDose, type: 'text' },
    { name: fields.dosePerCow, type: 'text' },
    { name: fields.gramsPerBatch, type: 'text' },
    { name: fields.costPerBatch, type: 'text' },
    { name: fields.sortKey, type: 'text' },
    { name: fields.updateData, type: 'text' },
    { name: fields.areaManager, type: 'text' },
  ],
  pk: [fields.id],
};
export default schema;
