export const name = 'MixType';
export const fields = {
  id: 'id',
  idMixType: 'value',
  mixType: 'label',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idMixType, type: 'text' },
    { name: fields.mixType, type: 'text' }
  ],
  pk: [fields.id],
};
export default schema;
