import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class Packaging {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }

  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async findItem(value) {
    let result = await dba.execute(`SELECT * FROM ${name} WHERE ${fields.idPackaging}='${value}'`);
    result = dba.getAll(result);
    return result;
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }


  static async create(data) {
    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);
  }

  static toItem(data) {
 
    let result = {
      [fields.idPackaging]:data.id,
      [fields.packaging]:data['field_252'],
      [fields.mixType]:data['field_255_raw'][0] ? data['field_255_raw'][0].id : '',
      [fields.cost]:data['field_253_raw'], 
      [fields.size]:data['field_294'] ? data['field_294'] : 0
    }

    //todo, should we parse cost to float or change datatype to string, it looks like code elsewhere might expect string.

    return result;
  }
}
