import { merge } from "lodash";
import { fields, name } from "./schema";
import dba from "utils/dba";
export default class Account {
  static async getAll() {
    let result = await dba.execute(
      `SELECT * FROM ${name} WHERE ${fields.profileKey} !='delete'`
    );
    result = dba.getAll(result);
    return result;
  }

  static async findFirst() {
    let result = await dba.execute(
      `SELECT * FROM ${name} WHERE ${fields.profileKey} !='delete'`
    );
    result = dba.getAll(result);
    if (result && result.length > 0) {
      return result[0];
    }
    return false;
  }

  static async deleteAll() {
    return dba.execute(`UPDATE ${name} SET ${fields.profileKey}='delete'`);
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async findItem(value) {
    let result = await dba.execute(
      `SELECT * FROM ${name} WHERE ${fields.email}='${value}'`
    );
    result = dba.getAll(result);
    return result;
  }

  static async acceptTerm(email) {
    return dba.execute(
      `UPDATE ${name} SET ${fields.acceptTerm}=${1} WHERE email='${email}'`
    );
  }

  static async updateInfo(email, markupMin, markupMax, nutritechMargin, resellerMargin) {

    if(nutritechMargin != null && resellerMargin != null) {
      return dba.execute(
        `UPDATE ${name} SET
         ${fields.markupMin}='${markupMin}',
         ${fields.markupMax}='${markupMax}',
         ${fields.nutritechMargin}='${nutritechMargin}',
         ${fields.resellerMargin}='${resellerMargin}'
         WHERE email='${email}\'`
      );
    }
    else
    {
      return dba.execute(
        `UPDATE ${name} SET
         ${fields.markupMin}='${markupMin}',
         ${fields.markupMax}='${markupMax}'
         WHERE email='${email}'`
      );
    }
  }

  static async updateAccept(email, nutritechMargin, resellerMargin) {
    return dba.execute(
      `UPDATE ${name} SET ${fields.nutritechMargin}=\'${nutritechMargin}", ${
        fields.resellerMargin
      }=\'${resellerMargin}" WHERE email="${email}\'`
    );
  }

  static async create(value) {

    let data = [{
      id:value.id,
      idAccount:value.idAccount,
      idManage:value.idManage,
      email:value.email,
      firstName:value.firstName,
      lastName:value.lastName,
      profileKey:value.profileKey,
      nutritechMargin:"",
      resellerMargin:"",
      acceptTerm:value.acceptTerm,
      markupMin:"",
      markupMax:""
    }];

      
    var sql = `INSERT INTO ${name} VALUES='@values'`; 
    
    return dba.executeQry(sql, data, true);
  }

  /*static toString(data, extension) {
    // console.log("tostring----------data----------", data);
    let result = `(
      "${data.id}", 
      "${data.idAccount}", 
      "${data.idManage}", 
      "${data.email}", 
      "${data.firstName}", 
      "${data.lastName}", 
      "${data.profileKey}", 
      "", 
      "", 
      ${data.acceptTerm} )`;
    if (extension) {
      result = merge(result, extension);
    }
    // console.log("toString", result);
    return result;
  }*/
}
