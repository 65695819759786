export const name = 'Region';
export const fields = {
  id: 'id',
  idRegion: 'idRegion',
  nameRegion: 'name',
  sequence: 'sequence',
};
const schema = {
  name,
  columns: [
    { name: fields.id, type: 'integer PRIMARY KEY  AUTOINCREMENT' },
    { name: fields.idRegion, type: 'text' },
    { name: fields.nameRegion, type: 'text' },
    { name: fields.sequence, type: 'integer' },
  ],
  pk: [fields.id],
};
export default schema;
