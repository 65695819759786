/**
 *
 * Asynchronously loads the component for NewRecommendation
 *
 */

import Loadable from "react-loadable";
import React from 'react';
import SpinnerModal from 'components/SpinnerModal';

export default Loadable({
  loader: () => import("./index"),
  loading: () => <SpinnerModal visible={true} message={'Loading...'} />,
});
