import { merge } from 'lodash';
import { fields, name } from './schema';
import dba from 'utils/dba';
export default class IncompatibleProducts {
  static async getAll() {
    let result = await dba.execute(`SELECT * FROM ${name}`);
    result = dba.getAll(result);
    return result;
  }
  static async databaseExit() {
    return dba.execute(`SELECT COUNT(id) as numberOfRecord FROM ${name}`);
  }

  static async deleteData() {
    return dba.execute(`DELETE FROM ${name}`);
  }

  static async create(data) {

    const sql = `INSERT INTO ${name} VALUES='@values'`; 
    return dba.executeQry(sql, data);

  }

  static toItem(data) {
 
    let result = {
      [fields.idProduct]:data.id,
      [fields.idProduct1]:data['field_66_raw'][0] ? data['field_66_raw'][0].id : '',
      [fields.product1]:data['field_66_raw'][0] ? data['field_66_raw'][0].identifier : '',
      [fields.idProduct2]:data['field_67_raw'][0] ? data['field_67_raw'][0].id : '',
      [fields.product2]:data['field_67_raw'][0] ? data['field_67_raw'][0].identifier : '',
    }

    return result;
  }
}
